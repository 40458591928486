import FinancingCalculation from './FinancingCalculation'

const FinancingCalculationOffensive = {
  interestRate: 5.75,
  processingCost: 150,
  calculation (deposit, period, price, withEom) {
    return FinancingCalculation.calculation(
      deposit,
      period,
      price,
      this.interestRate,
      0,
      this.processingCost,
      withEom,
    )
  },
}

export default FinancingCalculationOffensive
