const ButtonIconForwardSolo = {
  functional: true,
  template: `
      <div class="button-group">
        <div class="button__icon-arrow"></div>
      </div>
  `,
}

export default ButtonIconForwardSolo
