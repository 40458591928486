import FinancingCalculationLeasing from './FinancingCalculationLeasing'
import FinancingCalculationOffensive from './FinancingCalculationOffensive'
import FinancingCalculation44 from './FinancingCalculation44'
import { RoutingEnum } from '../enums/RoutingEnum'
import FinancingCalculationZero from './FinancingCalculationZero'
import FinancingCalculation718 from './FinancingCalculation718'

const FinancingTypeCalculation = (type, deposit, period, price, withEom = false, megameter = 0) => {
  switch (type) {
    case RoutingEnum.FINANCING_OFFENSIVE:
      return FinancingCalculationOffensive.calculation(
        deposit,
        period,
        price,
        withEom,
      )
    case RoutingEnum.FINANCING_44:
      return FinancingCalculation44.calculation(
        price,
        withEom,
      )
    case RoutingEnum.FINANCING_TYPE_ZERO:
      return FinancingCalculationZero.calculation(
        deposit,
        period,
        price,
        withEom,
        megameter,
      )
    case RoutingEnum.FINANCING_718:
      return FinancingCalculation718.calculation(
        deposit,
        period,
        price,
        withEom,
      )
    default:
      return FinancingCalculationLeasing.calculation(
        deposit,
        period,
        price,
        withEom,
      )
  }
}

export default FinancingTypeCalculation
