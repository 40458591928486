/* global VueRouter */

const UnknownPage = {
  template: `
<div class="unknown-page header-padding">
  <div class="bg-grey-gradient"></div>
  <div class="unknown-page__container" :style="{backgroundImage: 'url(' + contentData.image + ')'}">
    <div class="unknown-page__container-bg"></div>

    <div class="unknown-page__box">
      <div class="unknown-page__box-wrapper">
        <h1 class="unknown-page__title">{{ contentData.title }}</h1>
        <div class="unknown-page__text text-large">{{ contentData.text }}</div>
        <div class="unknown-page__cta">
          <div class="button-group" @click="router.push({name: 'home'})">
            <button class="button"><span>{{ contentData.cta }}</span></button>
            <div class="button__icon-arrow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  `,
  setup () {
    const router = VueRouter.useRouter()
    const contentData = window.UNKNOWN_PAGE_PARAMS

    return {
      router,
      contentData,
    }
  },
}

export default UnknownPage
