/* global Vue */

import ButtonIconForwardSolo from '../partials/ButtonIconForwardSolo'
import Hero from '../components/Hero'
import { RoutingEnum } from '../enums/RoutingEnum'

const Home = {
  template: `
<div class="home">
  <Hero
    :pages="content.hero_pages"
    :routingToDefault="true"
    :slideDownText="content.slide_down" />

  <div class="container">
    <div class="home-section-wrapper grid">
      <div class="home-section button-group-hover col-12 col-sm-6" v-for="section in sections">
        <router-link
          :to="{ name: routingEnumValues.ROUTE_LISTING, params: { financing_type: section.slug, model: routingEnumValues.ALL_MODELS } }"
          class="router-link">
            <ButtonIconForwardSolo />
            <img :src="section.image" />
            <div class="home-section__description">
              <h2>{{ section.name }}</h2>
              <p class="text-body">{{ section.description }}</p>
            </div>
        </router-link>
      </div>
    </div>
  </div>


  <div class="container">
    <h1 class="home-model-wrapper-title">{{ content.models_title }}</h1>
  </div>
  <div class="home-model-wrapper">
    <div
      class="home-model button-group-hover"
      v-for="model in models"
      :style="{ backgroundImage: 'url(' + model.image + ')' }">
        <router-link
          :to="{ name: routingEnumValues.ROUTE_LISTING, params: { financing_type: routingEnumValues.ALL_FINANCING_TYPES, model: model.name } }"
          class="router-link">
          <ButtonIconForwardSolo />
          <div class="home-model__title h1">{{ model.name }}</div>
        </router-link>
    </div>
  </div>
</div>
  `,
  components: { ButtonIconForwardSolo, Hero },
  setup () {
    const routingEnumValues = Object.assign({}, RoutingEnum)
    const content = window.HOME_PARAMS
    const sections = window.FINANCING_TYPES.filter(type => content.sections.indexOf(type.slug) !== -1).sort((a, b) => {
      return content.sections.indexOf(a.slug) - content.sections.indexOf(b.slug)
    })
    const models = window.MODELS
    const swiperEl = Vue.ref(null)

    return {
      routingEnumValues,
      content,
      sections,
      models,
      swiperEl,
    }
  },
}

export default Home
