const HeaderExternalLink = {
  template: `
      <div class="external" :class="{'is-hidden-md-down': desktopOnly}">
        <a class="button hover icon-forward-left" href="https://cc.porsche.com/icc/ccCall.do?lang=so&userID=SO&MODELYEAR">Porsche konfigurator</a>
      </div>
  `,
  props: {
    desktopOnly: {
      type: Boolean,
      default: false,
    },
  },
}

export default HeaderExternalLink
