export const RoutingEnum = Object.freeze({
  ALL_FINANCING_TYPES: 'vsa-financiranja',
  ALL_MODELS: 'vsi-modeli',
  ALL_FINANCING_TYPES_NAME: 'vsa financiranja',
  ROUTE_LISTING: 'listing',
  ROUTE_DETAIL: 'detail',
  FINANCING_TYPE_ZERO: 'financiranje-000',
  FINANCING_TYPE_LEASING: 'financni-leasing',
  MODELS_HYBRID: 'modeli-ehybrid',
  FINANCING_PLATINIUM: 'financiranje-platinum',
  FINANCING_44: 'financiranje-4-4',
  FINANCING_OFFENSIVE: 'financiranje-e-ofenziva',
  FINANCING_718: 'financiranje-718',
})
