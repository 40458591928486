import { RoutingEnum } from '../enums/RoutingEnum'

const SliderPeriodValues = Object.freeze({
  MAX_VALUES: {
    default: 96,
    zero: 60,
  },
  MIN_VALUES: {
    default: 24,
    zero: 36,
    seven_one_eight: 12,
  },
  MARKS_VALUES: {
    default: { 24: '24', 36: '36', 48: '48', 60: '60', 72: '72', 84: '84', 96: '96' },
    zero: { 36: '36', 48: '48', 60: '60' },
    seven_one_eight: { 12: '12', 24: '24', 36: '36', 48: '48', 60: '60', 72: '72', 84: '84', 96: '96' },
  },
  MAX: (financingType) => {
    return financingType === RoutingEnum.FINANCING_TYPE_ZERO
      ? SliderPeriodValues.MAX_VALUES.zero
      : SliderPeriodValues.MAX_VALUES.default
  },
  MIN: (financingType) => {
    return financingType === RoutingEnum.FINANCING_TYPE_ZERO
      ? SliderPeriodValues.MIN_VALUES.zero
      : financingType === RoutingEnum.FINANCING_718
        ? SliderPeriodValues.MIN_VALUES.seven_one_eight
        : SliderPeriodValues.MIN_VALUES.default
  },
  MARKS: (financingType) => {
    return financingType === RoutingEnum.FINANCING_TYPE_ZERO
      ? SliderPeriodValues.MARKS_VALUES.zero
      : financingType === RoutingEnum.FINANCING_718
        ? SliderPeriodValues.MARKS_VALUES.seven_one_eight
        : SliderPeriodValues.MARKS_VALUES.default
  },
})

export default SliderPeriodValues
