import { RoutingEnum } from '../enums/RoutingEnum'

const SliderDepositValues = Object.freeze({
  MAX_VALUES: {
    default: 90,
  },
  MIN_VALUES: {
    default: 10,
    zero: 20,
    zero_configuration: 20,
  },
  MARKS_VALUES: {
    default: { 10: '10%', 90: '90%' },
    zero: { 20: '20%', 90: '90%' },
    zero_configuration: { 20: '20%', 90: '90%' },
  },
  MAX: () => {
    return SliderDepositValues.MAX_VALUES.default
  },
  MIN: (financingType, periodValue) => {
    return financingType !== RoutingEnum.FINANCING_TYPE_ZERO
      ? SliderDepositValues.MIN_VALUES.default
      : SliderDepositValues.MIN_VALUES.zero_configuration[periodValue] || SliderDepositValues.MIN_VALUES.zero
  },
  MARKS: (financingType, periodValue) => {
    return financingType !== RoutingEnum.FINANCING_TYPE_ZERO
      ? SliderDepositValues.MARKS_VALUES.default
      : SliderDepositValues.MARKS_VALUES.zero_configuration[periodValue] || SliderDepositValues.MARKS_VALUES.zero
  },
})

export default SliderDepositValues
