const DetailVehicleLabels = {
  template: `
<div class="container sticky-hide">
  <div class="detail-vehicle__labels">
    <div class="detail-vehicle__labels-left">
      <span class="detail-vehicle__label fuel" v-show="vehicle.consumption?.combined">
        <i class="icon"></i> {{ vehicle.consumption?.combined }}
      </span>
      <span class="detail-vehicle__label electric" v-show="vehicle.consumption?.electric">
        <i class="icon"></i> {{ vehicle.consumption?.electric }}
      </span>
      <span class="detail-vehicle__label co2" v-show="vehicle.emissions_co2">
        <i class="icon"></i> {{ vehicle.emissions_co2 }}
      </span>
    </div>
    <div class="detail-vehicle__labels-right" v-show="vehicle.stock_url">
      <a :href="vehicle.stock_url">{{ externalLinkText }}</a>
    </div>
  </div>
</div>
  `,
  props: {
    vehicle: {
      type: Object,
      default: {},
    },
  },
  setup () {
    const externalLinkText = window.DETAIL_PARAMS.external_link_text

    return { externalLinkText }
  },
}

export default DetailVehicleLabels
