import { RoutingEnum } from '../enums/RoutingEnum'

const PaymentPeriodHelper = {
  text: (financingType) => {
    return financingType === RoutingEnum.FINANCING_44
      ? 'leto'
      : 'mesec'
  },
}

export default PaymentPeriodHelper
