import FinancingCalculation from './FinancingCalculation'

const FinancingCalculationZero = {
  interestRate: 0,
  processingCost: 150,
  minMonthlyPremium: 20,
  residualValues: {
    36: {
      10: 50,
      15: 49,
      20: 48,
      25: 47,
      30: 46,
      35: 44,
      40: 42,
      45: 40,
      50: 37,
    },
    48: {
      10: 43,
      15: 42,
      20: 41,
      25: 39,
      30: 37,
      35: 35,
      40: 32,
      45: 28,
      50: 25,
    },
    60: {
      10: 35,
      15: 33,
      20: 31,
      25: 29,
      30: 26,
      35: 22,
      40: 19,
      45: 17,
      50: 15,
    },
  },
  residualValue (period, megameter) {
    return FinancingCalculationZero.residualValues[period]?.[megameter] || 0
  },
  calculation (deposit, period, price, withEom, megameter) {
    return FinancingCalculation.calculation(
      deposit,
      period,
      price,
      this.interestRate,
      this.residualValue(period, megameter),
      this.processingCost,
      withEom,
    )
  },
}

export default FinancingCalculationZero
