/* global Vue, VueRouter, VeeValidate, dropdown, moment */

import Modal from './Modal'
import SloHolidays from '../helpers/SloHolidays'

const ModalOffer = {
  template: `
<Modal :open="open" @close="$emit('close')">
  <div v-show="!formSent && !formError">
    <h2 class="modal-mask__title">{{ content.title }}</h2>
    <p class="modal-mask__text">{{ content.description }}</p>

    <form class="grid form" ref="formEl">
      <div class="col-12" :class="{error: formErrors.name}">
        <input name="name" type="text" v-model="form.name" :placeholder="content.name_placeholder" maxlength="60" />
        <span class="form__field-error">{{ formErrors.name }}</span>
      </div>

      <div class="col-12" :class="{error: formErrors.email}">
        <input name="email" type="text" v-model="form.email" :placeholder="content.email_placeholder" maxlength="100" />
        <span class="form__field-error">{{ formErrors.email }}</span>
      </div>

      <div class="col-12" :class="{error: formErrors.phone}">
        <input name="phone" type="text" v-model="form.phone" :placeholder="content.phone_placeholder" maxlength="20" />
        <span class="form__field-error">{{ formErrors.phone }}</span>
      </div>

      <div class="col-12">
        <span class="form__label">{{ content.datetime_label }}</span>
        <div class="grid">
          <div class="col-6" :class="{error: formErrors.date}">
            <el-date-picker
              v-model="form.date"
              type="date"
              name="date"
              format="DD.MM.YYYY"
              :default-value="defaultDateTime"
              :shortcuts="[]"
              :placeholder="content.date_placeholder"
              :disabledDate="datepickerDisabledDate"></el-date-picker>
            <span class="form__field-error">{{ formErrors.date }}</span>
          </div>
          <div class="col-6" :class="{error: formErrors.time}">
            <div>
              <dropdown
                v-model="form.time"
                optionLabel="name"
                optionValue="code"
                :disabled="!form.date"
                :options="availableTimes"
                :placeholder="content.time_placeholder"
                 />
             </div>
             <span class="form__field-error">{{ formErrors.time }}</span>
          </div>
        </div>
      </div>

      <div class="col-12">
        <textarea name="comment" v-model="form.comment" :placeholder="content.comment_placeholder" maxlength="2000"></textarea>
      </div>

      <div class="col-12" :class="{error: formErrors.privacy}">
        <div class="form__checkbox">
          <label>
            <input type="checkbox" v-model="form.privacy" />
            <span class="icon"></span>
            <span class="text" v-html="content.privacy_placeholder"></span>
          </label>
        </div>
        <span class="form__field-error">{{ formErrors.privacy }}</span>
      </div>

      <div class="col-12">
        <div class="button-group" @click.prevent="sendForm">
          <button class="button button--red" id="oddajPovprasevanje"><span>{{ content.send_button }}</span></button>
          <div class="button__icon-arrow" id="oddajPovprasevanjeP"></div>
        </div>
      </div>
    </form>
  </div>

  <div v-show="formSent">
    <h2 class="modal-mask__title">{{ content.thank_you_title }}</h2>
    <p class="modal-mask__text">{{ content.thank_you_text }}</p>
  </div>

  <div v-show="formError">
    <h2 class="modal-mask__title">{{ content.error_title }}</h2>
    <p class="modal-mask__text">{{ content.error_text }}</p>
    <p class="modal-mask__text" v-html="content.error_contact"></p>
  </div>
</Modal>
  `,
  components: { Modal, dropdown },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    vehicle: {
      type: Object,
      default: {},
    },
    sliderValues: {
      type: Object,
      default: {},
    },
    slug: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 10000,
    },
  },
  setup (props) {
    const content = window.MODALS.offer
    const isLoaderOpened = Vue.inject('isLoaderOpened')
    const formEl = Vue.ref({})
    const form = Vue.reactive({ comment: '', privacy: false })
    const formErrors = Vue.reactive({})
    const validationSchema = {
      name: 'required|min:3',
      email: 'required|email',
      phone: 'required|phone',
      date: 'date',
      time: 'time',
      privacy: 'required',
    }
    const formSent = Vue.ref(false)
    const formError = Vue.ref(false)

    const route = VueRouter.useRoute()
    const minUnix = parseInt(route.meta.api.minUnix)
    const defaultDateTime = new Date(minUnix)
    const maxDateTime = new Date().setDate(new Date().getDate() + 30)
    const datepickerDisabledDate = (date) => {
      const dayOfWeek = date.getDay()
      const unixTimestamp = date.getTime()
      // isWeekend or minDate: currently active date must be included, when checking for min date
      if (dayOfWeek === 0 || dayOfWeek === 6 || unixTimestamp + 86399000 < minUnix || maxDateTime < unixTimestamp) {
        return true
      }

      const holidays = SloHolidays.holidayYearList(date.getFullYear().toString())
      return !!holidays[moment(date).format('DD.MM')]
    }
    const datepickerAvaliableTimes = () => {
      const possibles = ['08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00',
        '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30']
      if (form.date && form.date.getTime() < minUnix) {
        const checkDate = new Date(form.date.getTime())
        for (let ii = possibles.length - 1; ii >= 0; ii--) {
          const time = possibles[ii].split(':')
          checkDate.setHours(parseInt(time[0]), parseInt(time[1]))
          if (checkDate.getTime() < minUnix) {
            possibles.splice(ii, 1)
          }
        }
      }

      return [{ name: content.time_placeholder, code: '' }].concat(possibles.map(option => {
        return { name: option, code: option }
      }))
    }
    const availableTimes = Vue.ref(datepickerAvaliableTimes())

    const { handleSubmit } = VeeValidate.useForm({ validationSchema })
    Object.keys(validationSchema).forEach((field) => {
      const { value: fieldValue, errorMessage: fieldError } = VeeValidate.useField(field)
      form[field] = fieldValue
      formErrors[field] = fieldError
    })

    const sendForm = handleSubmit(async () => {
      isLoaderOpened.value = true
      try {
        const financingType = window.FINANCING_TYPES.filter(type => type.slug === props.slug)[0]
        const response = await fetch(`${window.API_URL}/api/financing-api/porsche-offer-request`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            form,
            vehicle: props.vehicle,
            financingData: {
              ...props.sliderValues,
              financingType: financingType.email_name,
              hasKm: financingType.show_km_slider || false,
              price: props.price,
            },
          }),
        })

        if (!response.ok) {
          formError.value = true
        } else {
          formSent.value = true
        }
        isLoaderOpened.value = false
      } catch (err) {
        console.error(err)
        isLoaderOpened.value = false
      }
    })

    Vue.watch(() => form.date, newVal => {
      availableTimes.value = datepickerAvaliableTimes()
      if ((!newVal || availableTimes.value.indexOf(form.time) === -1) && form.time) {
        form.time = ''
      }
    })

    return {
      content,
      formEl,
      form,
      formErrors,
      formSent,
      formError,
      defaultDateTime,
      datepickerDisabledDate,
      availableTimes,
      sendForm,
    }
  },
}

export default ModalOffer
