/* global moment */

const SloHolidays = {
  compiled: {},
  fixedHolidays: {
    '01.01': 'Novo leto',
    '02.01': 'Novo leto',
    '08.02': 'Prešernov dan, slovenski kulturni praznik',
    27.04: 'Dan upora proti okupatorju',
    '01.05': 'Praznik dela',
    '02.05': 'Praznik dela',
    25.06: 'Dan državnosti',
    '01.11': 'Dan spomina na mrtve',
    26.12: 'Dan samostojnosti in enotnosti',
    15.08: 'Marijino vnebovzetje',
    '31.10': 'Dan reformacije',
    25.12: 'Božič',
  },
  holidayYearList (year = null) {
    if (!year) {
      year = moment().format('YYYY')
    }

    if (this.compiled[year]) {
      return this.compiled[year]
    }
    return this.compileYearHolidays(Number(year))
  },
  compileYearHolidays (year) {
    const a = year % 19
    const b = year % 4
    const c = year % 7
    const k = Math.floor(year / 100)
    const p = Math.floor((13 + 8 * k) / 25)
    const q = Math.floor(k / 4)
    const M = (15 - p + k - q) % 30
    const N = (4 + k - q) % 7
    const d = (19 * a + M) % 30
    const e = (2 * b + 4 * c + 6 * d + N) % 7

    let day, month
    if (d + e < 9) {
      day = 22 + d + e
      month = 3
    } else {
      day = d + e - 9
      month = 4
    }

    if (d === 29 && e === 6 && day === 26) {
      day = 19
    }
    if (d === 28 && e === 6 && (11 * M + 11) % 30 < 19 && day === 25) {
      day = 18
    }

    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }

    const allHolidays = { ...this.fixedHolidays }
    allHolidays[day + '.' + month] = 'Velikonočna nedelja'
    allHolidays[moment(day + '.' + month + '.' + year, 'DD.MM.YYYY').add(1, 'd').format('DD.MM')] = 'Velikonočni ponedeljek'
    allHolidays[moment(day + '.' + month + '.' + year, 'DD.MM.YYYY').add(49, 'd').format('DD.MM')] = 'Binkoštna nedelja'

    this.compiled[year] = { ...allHolidays }
    return this.compiled[year]
  },
}

export default SloHolidays
