const Modal = {
  template: `
<transition name="modal">
  <div class="modal-mask" v-show="open">
    <div class="modal-mask__window">
      <div class="modal-mask__window-close" @click="close"></div>
      <div class="modal-mask__window-body">
        <slot></slot>
      </div>
    </div>
  </div>
</transition>
  `,
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const close = () => emit('close')
    return { close }
  },
}

export default Modal
