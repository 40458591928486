/* global Vue, VueRouter, ElementPlus, VueToast, primevue */

import Header from './components/Header'
import Home from './views/Home'
import Listing from './views/Listing'
import Detail from './views/Detail'
import UnknownPage from './views/UnknownPage'
import { RoutingEnum } from './enums/RoutingEnum'
import { RouteApiResolver } from './helpers/RouteApiResolver'
import './validationRules'
import ElementPlusLocale from './enums/ElementPlusLocale'
import Loader from './components/Loader'

const isLoaderOpened = Vue.ref(false)
const App = {
  template: `
    <Loader />
    <Header />

    <router-view></router-view>
  `,
  components: { Header, Loader },
  setup () {
    Vue.provide('isLoaderOpened', isLoaderOpened)
  },
}

const router = VueRouter.createRouter({
  history: window.LOCALHOST ? VueRouter.createWebHashHistory() : VueRouter.createWebHistory(),
  routes: [
    { name: 'home', path: '/', component: Home },
    {
      path: `/${RoutingEnum.FINANCING_PLATINIUM}/:model`,
      redirect: { name: 'home', },
    },
    {
      path: `/${RoutingEnum.FINANCING_PLATINIUM}/:model_name/:vehicle`,
      redirect: { name: 'home', },
    },
    {
      name: RoutingEnum.ROUTE_LISTING,
      path: '/:financing_type/:model',
      component: Listing,
      beforeEnter: (to, from, next) => {
        isLoaderOpened.value = true
        RouteApiResolver.getData(to, from, next)
      },
    },
    {
      name: RoutingEnum.ROUTE_DETAIL,
      path: '/:financing_type/:model_name/:vehicle',
      component: Detail,
      beforeEnter: (to, from, next) => {
        isLoaderOpened.value = true
        RouteApiResolver.getData(to, from, next)
      },
    },
    {
      path: `/${RoutingEnum.FINANCING_TYPE_ZERO}`,
      redirect: {
        name: RoutingEnum.ROUTE_LISTING,
        params: { financing_type: RoutingEnum.FINANCING_TYPE_ZERO, model: RoutingEnum.ALL_MODELS },
      },
    },
    {
      path: `/${RoutingEnum.FINANCING_TYPE_LEASING}`,
      redirect: {
        name: RoutingEnum.ROUTE_LISTING,
        params: { financing_type: RoutingEnum.FINANCING_TYPE_LEASING, model: RoutingEnum.ALL_MODELS },
      },
    },
    {
      path: `/${RoutingEnum.FINANCING_718}`,
      redirect: {
        name: RoutingEnum.ROUTE_LISTING,
        params: { financing_type: RoutingEnum.FINANCING_718, model: RoutingEnum.ALL_MODELS },
      },
    },
    {
      path: `/${RoutingEnum.MODELS_HYBRID}`,
      redirect: {
        name: RoutingEnum.ROUTE_LISTING,
        params: { financing_type: RoutingEnum.ALL_FINANCING_TYPES, model_name: RoutingEnum.MODELS_HYBRID },
      },
    },
    {
      path: `/${RoutingEnum.FINANCING_PLATINIUM}`,
      redirect: { name: 'home', },
    },
    { name: '404', path: '/:pathMatch(.*)*', component: UnknownPage },
  ],
})
router.afterEach((to, from) => {
  isLoaderOpened.value = false
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

const app = Vue.createApp(App)
app.use(router)
app.use(ElementPlus, { locale: ElementPlusLocale })
app.use(VueToast)
app.use(primevue)
app.config.globalProperties.$filters = {
  formatNumber (number) {
    return Intl.NumberFormat('sl-SI', { minimumFractionDigits: 2 }).format(number)
  },
}
app.mount('#app')
