const RouteParse = {
  selectedFinancingType: (isHybridSpecialPage, isFinancingTypeSingle, routingFinancingType) => {
    if (isHybridSpecialPage) {
      return window.EXTRA_LISTING_PAGES[0]
    } else if (isFinancingTypeSingle) {
      return window.FINANCING_TYPES.filter(financingType => financingType.slug === routingFinancingType)[0] || {}
    } else {
      return {}
    }
  },
}

export default RouteParse
