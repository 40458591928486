/* global Vue, VueRouter, Swiper, SwiperSlide */

import { RoutingEnum } from '../enums/RoutingEnum'
import Hero from '../components/Hero'
import Vehicle from '../components/Vehicle'
import ModelBanner from '../partials/ModelBanner'
import ListingTitle from '../partials/ListingTitle'
import RouteParse from '../helpers/RouteParse'

const Listing = {
  template: `
<div class="listing">
  <Hero
    :image="selectedFinancingType.image"
    :subtitle="selectedFinancingType.label"
    :title="selectedFinancingType.name"
    :description="selectedFinancingType.description"
    :cta="selectedFinancingType.info_text"
    :hrefLink="selectedFinancingType.info_link"
    :hrefAttrId="selectedFinancingType.info_attr_id"
    :slideDownText="contentData.slide_down"
    :ctaArrowDown="true"
    v-if="isHybridSpecialPage || isFinancingTypeSingle" />

  <ModelBanner
    :chosen="chosenModel"
    v-if="!isModelSingle"
    :class="{'header-padding': !isFinancingTypeSingle}"
    @chooseModel="chooseModel" />

  <div class="container listing__vehicles-wrapper" :class="{'header-padding': isModelSingle, 'reverse-order': stockVehiclesFirst}">
    <div class="listing__vehicles" v-for="vehicleListing in vehicleListings"
      v-show="(!vehicleListing.stock && areVisibleConfiguratorVehicles) || (vehicleListing.stock && areVisibleStockVehicles)">
      <ListingTitle
        :chosenModel="chosenModel"
        :count="vehicleListing.vehicles.length"
        :arrowUp="vehicleListing.arrowUp"
        @orderClick="vehicleListing.orderClick"
        :stock="vehicleListing.stock" />

      <div class="grid">
        <div class="col-12 col-sm-6 col-lg-4" v-for="vehicle in vehicleListing.vehicles" :key="vehicle.id">
          <Vehicle :vehicle="vehicle" />
        </div>
      </div>
    </div>
  </div>
</div>
  `,
  components: {
    Hero,
    ModelBanner,
    Vehicle,
    ListingTitle,
    Swiper,
    SwiperSlide,
  },
  setup () {
    const contentData = window.LISTING_PARAMS
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const routingFinancingType = Vue.ref(route.params.financing_type)
    const routingModel = Vue.ref(route.params.model)
    const isHybridSpecialPage = Vue.computed(() => routingModel.value === RoutingEnum.MODELS_HYBRID)
    const isFinancingTypeSingle = Vue.computed(() => routingFinancingType.value !== RoutingEnum.ALL_FINANCING_TYPES)
    const stockVehiclesFirst = Vue.computed(() => routingFinancingType.value === RoutingEnum.FINANCING_TYPE_ZERO || routingFinancingType.value === RoutingEnum.FINANCING_44)
    const isModelSingle = Vue.ref(routingModel.value !== RoutingEnum.ALL_MODELS && !isHybridSpecialPage.value)
    function validateModelParam () {
      if ((routingModel.value && isModelSingle.value && !isHybridSpecialPage.value && !window.MODELS.filter(model => String(model.name) === routingModel.value).length) ||
       (routingFinancingType.value && isFinancingTypeSingle.value && !window.FINANCING_TYPES.filter(f => String(f.slug) === routingFinancingType.value).length)) {
        router.push({ name: '404' })
      }
    }
    validateModelParam()
    const selectedFinancingType = Vue.computed(() => RouteParse.selectedFinancingType(isHybridSpecialPage.value, isFinancingTypeSingle.value, routingFinancingType.value))
    const areVisibleConfiguratorVehicles = Vue.computed(() => !isFinancingTypeSingle.value || selectedFinancingType.value.has_configurator_vehicles)
    const areVisibleStockVehicles = Vue.computed(() => !isFinancingTypeSingle.value || selectedFinancingType.value.has_stock_vehicles)

    const chosenModel = Vue.ref(null)
    const chooseModel = (newVal) => {
      if (chosenModel.value === newVal) {
        return
      }
      chosenModel.value = newVal
    }

    Vue.watch(() => route.params.model, param => {
      routingModel.value = param
      isModelSingle.value = param !== RoutingEnum.ALL_MODELS && !isHybridSpecialPage.value
      validateModelParam()
    })
    function isModelAndVehicleAvailableOnFinancingType (vehicle) {
      const modelsOnly = selectedFinancingType.value.models_only || []
      const disabledVehicles = selectedFinancingType.value.disabled_vehicles || []
      return (modelsOnly.length
        ? modelsOnly.filter(model => vehicle.model_name.toLowerCase().indexOf(model) === 0).length
        : true
      ) && (disabledVehicles.length
        ? !disabledVehicles.filter(vehicleId => vehicle.vehicle_code === vehicleId).length
        : true
      )
    }

    function filterVehicles (vehicleArray, isStock = false) {
      let list = !chosenModel.value && !isModelSingle.value
        ? vehicleArray
        : vehicleArray.filter(vehicle => vehicle.model_name.toLowerCase().indexOf(isModelSingle.value
          ? routingModel.value
          : chosenModel.value) === 0)
      if (isHybridSpecialPage.value) {
        list = list.filter(vehicle => vehicle.name.toLowerCase().includes('e-hybrid'))
      }
      if (selectedFinancingType.value.new_vehicles_only) {
        list = list.filter(vehicle => vehicle.state_key === 'new')
      }
      if (selectedFinancingType.value.ignore_electric_vehicles) {
        list = list.filter(vehicle => !(vehicle.name.includes('Electric') || vehicle.fuel === 'Električni'))
      }
      if (!isStock && selectedFinancingType.value.ignore_configurator_models) {
        list = list.filter(vehicle => !selectedFinancingType.value.ignore_configurator_models.filter(m => vehicle.model_name.toLowerCase().includes(m)).length)
      }
      return isFinancingTypeSingle.value
        ? list.filter(vehicle => isModelAndVehicleAvailableOnFinancingType(vehicle))
        : list
    }
    const availableVehicles = route.meta.api.vehicles.filter(vehicle => !vehicle.stock_url)
    const availableVehiclesOrderBy = Vue.ref(1)
    const availableVehiclesChangeOrder = () => { availableVehiclesOrderBy.value = availableVehiclesOrderBy.value * -1 }
    const filteredAvailableVehicles = Vue.computed(() => {
      return filterVehicles(availableVehicles).sort((a, b) => (a.price - b.price) * availableVehiclesOrderBy.value)
    })

    const stockVehicles = route.meta.api.vehicles.filter(vehicle => vehicle.stock_url)
    const filteredStockVehicles = Vue.computed(() => {
      const isOffensive = routingFinancingType.value === RoutingEnum.FINANCING_OFFENSIVE
      return filterVehicles(stockVehicles, true).filter(vehicle => !isOffensive || vehicle.state_key === 'new').sort((a, b) => (a.price - b.price))
    })

    const vehicleListings = Vue.reactive([
      {
        vehicles: filteredAvailableVehicles,
        arrowUp: availableVehiclesOrderBy > 0,
        orderClick: availableVehiclesChangeOrder,
        position: 0,
      },
      {
        vehicles: filteredStockVehicles,
        stock: true,
        position: 1,
      },
    ])

    // watch route params change
    Vue.watch(() => route.params.financing_type, param => {
      routingFinancingType.value = param
      vehicleListings.sort((a, b) => ((a.position - b.position) * (routingFinancingType.value === RoutingEnum.FINANCING_OFFENSIVE ? -1 : 1)))
    }, { immediate: true })

    return {
      contentData,
      isHybridSpecialPage,
      isFinancingTypeSingle,
      stockVehiclesFirst,
      isModelSingle,
      selectedFinancingType,
      areVisibleConfiguratorVehicles,
      areVisibleStockVehicles,
      chosenModel,
      chooseModel,
      availableVehiclesOrderBy,
      availableVehiclesChangeOrder,
      filteredAvailableVehicles,
      filteredStockVehicles,
      vehicleListings,
    }
  },
}

export default Listing
