const DetailVehicleTechnicals = {
  template: `
<div class="detail-vehicle__technicals">
  <div class="detail-vehicle__technical">
    <span class="detail-vehicle__technical-value">{{ vehicle.kw }} kW/{{ vehicle.hp }} KM</span>
    <span class="detail-vehicle__technical-name">{{ contentData.technical_power }}</span>
  </div>
  <div class="detail-vehicle__technical">
    <span class="detail-vehicle__technical-value">{{ vehicle.transmission_description }}</span>
    <span class="detail-vehicle__technical-name">{{ contentData.technical_transmission }}</span>
  </div>
  <div class="detail-vehicle__technical" v-show="isStockVehicle">
    <span class="detail-vehicle__technical-value">{{ vehicle.fuel }}</span>
    <span class="detail-vehicle__technical-name">{{ contentData.technical_fuel }}</span>
  </div>
  <div class="detail-vehicle__technical" v-show="!isStockVehicle">
    <span class="detail-vehicle__technical-value">{{ vehicle.acceleration }}</span>
    <span class="detail-vehicle__technical-name">{{ contentData.technical_acceleration }}</span>
  </div>
  <div class="detail-vehicle__technical" v-show="!isStockVehicle">
    <span class="detail-vehicle__technical-value">{{ vehicle.max_speed }}</span>
    <span class="detail-vehicle__technical-name">{{ contentData.technical_max_speed }}</span>
  </div>
  <div class="detail-vehicle__technical" v-show="isStockVehicle">
    <span class="detail-vehicle__technical-value">{{ vehicle.year }}</span>
    <span class="detail-vehicle__technical-name">{{ contentData.technical_year }}</span>
  </div>
  <div class="detail-vehicle__technical" v-show="isStockVehicle">
    <span class="detail-vehicle__technical-value">{{ vehicle.km }}</span>
    <span class="detail-vehicle__technical-name">{{ contentData.technical_km }}</span>
  </div>
</div>
  `,
  props: {
    vehicle: {
      type: Object,
      default: {},
    },
  },
  setup (props) {
    const contentData = window.DETAIL_PARAMS
    const isStockVehicle = !!props.vehicle.stock_url
    return { contentData, isStockVehicle }
  },
}

export default DetailVehicleTechnicals
