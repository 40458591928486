/* global Vue, VueRouter */

import { RoutingEnum } from '../enums/RoutingEnum'
import TransitionCollapse from '../transitions/TransitionCollapse'

const DetailFinancingTypes = {
  template: `
<div class="detail-financing-types">
  <div class="container">
    <h2 class="detail-financing-types__title">{{ financingTypesTitle }}</h2>

    <div v-show="!isFinancingTypeSingle && financingTypes.length > 1">
      <div class="detail-financing-types__select">
        <div class="detail-financing-types__mobile is-hidden-sm-up">
          <div class="detail-financing-types__mobile-cta">
            <div class="button-group" @click="openFinancingTypeDropdown = !openFinancingTypeDropdown">
              <button class="button"><span>{{ selectedFinancingType.name }}</span></button>
              <div class="button__icon-arrow down"></div>
            </div>
          </div>

            <TransitionCollapse>
              <div class="detail-financing-types__options" v-show="openFinancingTypeDropdown">
                <div
                  class="detail-financing-types__option"
                  v-for="financingType in financingTypes"
                  :class="{active: financingType.slug === selectedFinancingType.slug}"
                  @click="changeFinancingType(financingType)">
                    <span>{{ financingType.name }}</span>
                </div>
              </div>
            </TransitionCollapse>
        </div>

        <div class="detail-financing-types__options is-hidden-sm-down">
          <div
            class="detail-financing-types__option"
            v-for="financingType in financingTypes"
            :class="{active: financingType.slug === selectedFinancingType.slug}"
            @click="changeFinancingType(financingType)">
              <span>{{ financingType.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-financing-types__description">
      <span class="detail-financing-types__description-title h3">{{ selectedFinancingType.name }}</span>
      <p class="detail-financing-types__description-text">{{ selectedFinancingType.description }}</p>
      <p class="detail-financing-types__description-text">{{ selectedFinancingType.additional_description }}</p>
      <span class="detail-financing-types__description-cta">
        <a :href="selectedFinancingType.info_link" :id="selectedFinancingType.info_attr_id + 'Detail'" class="detail-financing-types__description-link" download>
          <span>{{ contentData.financing_more }}</span>
          <span class="detail-financing-types__description-arrow"> </span>
        </a>
      </span>
    </div>
  </div>

  <hr />
</div>
`,
  components: { TransitionCollapse },
  props: {
    modelName: {
      type: String,
      default: '',
    },
    vehicleCode: {
      type: String,
      default: '',
    },
    isUsed: {
      type: Boolean,
      default: false,
    },
    fuel: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const contentData = window.DETAIL_PARAMS
    const route = VueRouter.useRoute()
    const isFinancingTypeSingle = Vue.computed(() => route.params.financing_type !== RoutingEnum.ALL_FINANCING_TYPES)
    const isStock = Vue.computed(() => !!(route.meta.api.vehicles.filter(car => car.vehicle_code === route.params.vehicle)[0] || {}).stock_url )

    const financingTypes = Vue.computed(() => {
      if (isFinancingTypeSingle.value) {
        return window.FINANCING_TYPES
      } else {
        return window.FINANCING_TYPES.filter((type) => {
          if (type.new_vehicles_only && props.isUsed) {
            return false
          } else {
            return (!type.models_only || type.models_only.filter(model => props.modelName.toLowerCase().includes(model)).length) &&
              (!type.disabled_vehicles || !type.disabled_vehicles.filter(vehicleCode => props.vehicleCode === vehicleCode).length) &&
              (!type.ignore_electric_vehicles || !(props.modelName.includes('Electric') || props.fuel === 'Električni')) &&
              ((type.has_stock_vehicles && isStock.value) || (type.has_configurator_vehicles && !isStock.value)) &&
              (isStock.value || !type.ignore_configurator_models || !type.ignore_configurator_models.filter(m => props.modelName.toLowerCase().includes(m)).length)
          }
        })
      }
    })

    const selectedFinancingType = Vue.ref(isFinancingTypeSingle.value
      ? window.FINANCING_TYPES.filter(financingType => financingType.slug === route.params.financing_type)[0] || {}
      : financingTypes.value[0],
    )
    const financingTypesTitle = Vue.computed(() =>
      window.FINANCING_TYPES.filter(financingType => financingType.slug === selectedFinancingType.value?.slug)[0]?.cta_special_text ||
        contentData.cta_text,
    )
    const openFinancingTypeDropdown = Vue.ref(false)

    const changeFinancingType = (financingType) => {
      selectedFinancingType.value = financingType
      openFinancingTypeDropdown.value = false
      emit('changed', selectedFinancingType.value)
    }

    emit('changed', selectedFinancingType.value)

    return {
      contentData,
      isFinancingTypeSingle,
      financingTypes,
      financingTypesTitle,
      selectedFinancingType,
      openFinancingTypeDropdown,
      changeFinancingType,
    }
  },
}

export default DetailFinancingTypes
