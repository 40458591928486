/* global Vue, VueRouter */

import { RoutingEnum } from '../enums/RoutingEnum'
import RouteParse from '../helpers/RouteParse'

const ModelBanner = {
  template: `
<div class="model-banner-wrapper" v-show="models.length > 1">
  <div class="model-banner" :class="{open: mobileMenuOpened}">
    <div class="container is-hidden-sm-up">
      <div class="button-group" @click="mobileMenuToggle()">
        <div class="button"><span>Izberite model Porsche</span></div>
        <div class="button__icon-arrow no-line" :class="{down: !mobileMenuOpened, up: mobileMenuOpened}"></div>
      </div>
      <div class="model-banner__space">&nbsp;</div>
    </div>

    <div class="container">
      <div class="model-banner__items">
        <div
          class="model-banner__item"
          v-for="model in models"
          :class="{active: model.name === chosen}"
          @click="modelClick(model.name)">
            {{ model.name }}
        </div>
      </div>
    </div>
  </div>
</div>
  `,
  props: {
    chosen: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const mobileMenuOpened = Vue.ref(false)
    const route = VueRouter.useRoute()
    const routingFinancingType = Vue.ref(route.params.financing_type)
    const isHybridSpecialPage = Vue.computed(() => route.params.model === RoutingEnum.MODELS_HYBRID)
    const isFinancingTypeSingle = Vue.ref(routingFinancingType.value !== RoutingEnum.ALL_FINANCING_TYPES)
    const storageName = 'porsche-chosen-model'
    function getFinancingType () { return RouteParse.selectedFinancingType(isHybridSpecialPage.value, isFinancingTypeSingle.value, routingFinancingType.value) }
    const selectedFinancingType = Vue.ref(getFinancingType())

    // filter models
    function compileModels () {
      const modelsOnly = selectedFinancingType.value.models_only || []
      return modelsOnly.length
        ? window.MODELS.filter(model => modelsOnly.filter(filtered => filtered === model.name).length)
        : window.MODELS
    }
    const models = Vue.ref(compileModels())
    const areModelsLimitedToSingle = () => models.value.length === 1
    function saveChosenModelToSession (modelName) {
      if (!areModelsLimitedToSingle()) { // save only when model is not limited
        sessionStorage.setItem(storageName, modelName)
      }
    }
    function checkSinglePossibleModel () {
      let emitValue = areModelsLimitedToSingle()
        ? models.value[0].name
        : models.value.filter(model => String(model.name) === sessionStorage.getItem(storageName))[0]?.name || null
      if (!emitValue) { // force default model
        emitValue = models.value.filter(model => model.name === window.LISTING_PARAMS.default_model)[0]?.name || null
      }
      if (!emitValue) {
        emitValue = models.value[0]?.name || null
      }

      saveChosenModelToSession(emitValue)
      emit('chooseModel', emitValue)
    }
    checkSinglePossibleModel()

    Vue.watch(() => route.params.financing_type, param => {
      routingFinancingType.value = param
      isFinancingTypeSingle.value = routingFinancingType.value !== RoutingEnum.ALL_FINANCING_TYPES
      const financingType = getFinancingType()
      if (financingType.slug || selectedFinancingType.value) {
        selectedFinancingType.value = financingType
      }
      models.value = compileModels()
      checkSinglePossibleModel()
    })
    Vue.watch(() => route.params.model, param => {
      selectedFinancingType.value = getFinancingType()
      models.value = compileModels()
      checkSinglePossibleModel()
    })

    const modelClick = (name) => {
      mobileMenuOpened.value = false
      saveChosenModelToSession(name)
      emit('chooseModel', name)
    }
    const mobileMenuToggle = () => { mobileMenuOpened.value = !mobileMenuOpened.value }

    return {
      models,
      mobileMenuOpened,
      modelClick,
      mobileMenuToggle,
    }
  },
}

export default ModelBanner
