import FinancingCalculation from './FinancingCalculation'

const FinancingCalculation44 = {
  interestRate: 4,
  processingCost: 150,
  deposit: 25,
  period: 3,
  calculation (price, withEom) {
    const result = FinancingCalculation.calculation(
      this.deposit,
      this.period,
      price,
      this.interestRate,
      0,
      this.processingCost,
      withEom,
      1,
      false,
    )

    return result
  },
}

export default FinancingCalculation44
