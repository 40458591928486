/* global Vue */

import TransitionCollapse from '../transitions/TransitionCollapse'
import DetailVehicleLabels from '../partials/DetailVehicleLabels'
import DetailVehicleTechnicals from '../partials/DetailVehicleTechnicals'
import DetailVehicleSlider from '../partials/DetailVehicleSlider'

const DetailVehicle = {
  template: `
<div class="detail-vehicle" :class="{ sticky: isSticky }">
  <div class="container detail-vehicle__title-group">
    <div class="detail-vehicle__title">
      <h1 class="detail-vehicle__title-name">{{ vehicle.name }}</h1>
      <div class="detail-vehicle__title-labels" v-show="vehicle.stock_url || vehicle.ps_approved">
        <span class="detail-vehicle__title-label" v-show="vehicle.stock_url">{{ vehicle.state_name }}</span>
        <img src="/media/icons/porsche_approved.png" v-show="vehicle.ps_approved" />
      </div>
    </div>
  </div>

  <div class="detail-vehicle__dropdown is-hidden-sm-up">
    <div class="container">
      <div class="detail-vehicle__dropdown-toggle" @click="dropdownOpened = !dropdownOpened">
        <span class="detail-vehicle__dropdown-text">{{ contentData.mobile_show_vehicle_data }}</span>
        <span class="detail-vehicle__dropdown-icon"></span>
      </div>
    </div>

    <TransitionCollapse>
      <div class="detail-vehicle__dropdown-items" v-show="dropdownOpened">
        <div class="container detail-vehicle__dropdown-items--container">
          <DetailVehicleLabels :vehicle="vehicle" />
          <DetailVehicleTechnicals :vehicle="vehicle" />
        </div>
      </div>
    </TransitionCollapse>
  </div>

  <DetailVehicleLabels :vehicle="vehicle" class="is-hidden-sm-down" />

  <div class="detail-vehicle__price-group">
    <div class="container detail-vehicle__price-group-wrapper">
      <div class="detail-vehicle__price">
        <div class="detail-vehicle__price-total">
          <span class="detail-vehicle__price-value h3">{{ $filters.formatNumber(price) }} €</span>
          <span
            class="detail-vehicle__price-change"
            id="spremeniteCeno"
            @click="$emit('changePrice')"
            v-show="!vehicle.stock_url">
              <span class="is-hidden-sm-down">{{ contentData.change_price }}</span>
          </span>
        </div>
        <div class="detail-vehicle__price-ration">
          <span><b>{{ $filters.formatNumber(monthlyPayment) }}</b> €/{{ periodText }}</span>
        </div>
      </div>
      <div class="detail-vehicle__cta">
        <div class="button-group" @click="$emit('scroll')">
          <button class="button button--red"><span>{{ focusSlidersText }}</span></button>
          <div class="button__icon-arrow down"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="container is-hidden-sm-down sticky-hide">
    <DetailVehicleTechnicals :vehicle="vehicle" />
  </div>
</div>
<div ref="bottomStickyEl"></div>

<DetailVehicleSlider v-show="isStockVehicle" :images="vehicle.images" />
  `,
  components: { TransitionCollapse, DetailVehicleLabels, DetailVehicleTechnicals, DetailVehicleSlider },
  emits: ['changePrice', 'scroll'],
  props: {
    vehicle: {
      type: Object,
      default: {},
    },
    price: {
      type: Number,
      default: 0,
    },
    monthlyPayment: {
      type: Number,
      default: 0,
    },
    periodText: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const contentData = window.DETAIL_PARAMS
    const dropdownOpened = Vue.ref(false)
    const isSticky = Vue.ref(false)
    const bottomStickyEl = Vue.ref(null)
    const isStockVehicle = !!props.vehicle.stock_url

    const focusSlidersText = Vue.computed(() =>
      window.FINANCING_TYPES.filter(financingType => financingType.slug === props.slug)[0]?.cta_special_text ||
        contentData.cta_text,
    )

    let headerObserver = null
    Vue.onMounted(() => {
      headerObserver = new window.IntersectionObserver((entries) => {
        isSticky.value = !dropdownOpened.value && !entries[0].isIntersecting
      }, {
        rootMargin: '0px',
        threshold: 1.0,
      })
      headerObserver.observe(bottomStickyEl.value)
    })

    Vue.onUnmounted(() => {
      headerObserver.disconnect()
    })

    return {
      contentData,
      dropdownOpened,
      isSticky,
      bottomStickyEl,
      isStockVehicle,
      focusSlidersText,
    }
  },
}

export default DetailVehicle
