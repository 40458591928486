/* global Vue, VueRouter */

import { RoutingEnum } from '../enums/RoutingEnum'

const HeaderInternalLink = {
  template: `
    <div class="routing" :class="{'is-hidden-md-down': desktopOnly}">
      <router-link
        v-for="link in routerLinks"
        :to="{
          name: routingEnum.ROUTE_LISTING,
          params: { financing_type: link.financing_type, model: routingEnum.ALL_MODELS }
        }"
        class="routing__link router-link"
        :class="{ active: !isHybridModel && activeRoute === link.financing_type }"
        >{{ link.name }}</router-link>
      <router-link
        v-for="link in extraRouterLinks"
        :to="{
          name: routingEnum.ROUTE_LISTING,
          params: { financing_type: routingEnum.ALL_FINANCING_TYPES, model: link.slug }
        }"
        class="routing__link router-link is-hidden"
        :class="{ active: isHybridModel }"
        >{{ link.name }}</router-link>
    </div>
  `,
  props: {
    desktopOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const route = VueRouter.useRoute()
    const activeRoute = Vue.computed(() => route.params.financing_type)
    const isHybridModel = Vue.computed(() => route.params.model === RoutingEnum.MODELS_HYBRID)
    const routingEnum = Object.assign({}, RoutingEnum)
    const routerLinks = [{
      name: RoutingEnum.ALL_FINANCING_TYPES_NAME,
      financing_type: RoutingEnum.ALL_FINANCING_TYPES,
    }].concat(window.FINANCING_TYPES.filter(ft => ft.slug !== RoutingEnum.FINANCING_OFFENSIVE).map(financingType => {
      return {
        name: financingType.name,
        financing_type: financingType.slug,
      }
    }))
    const extraRouterLinks = window.EXTRA_LISTING_PAGES

    return {
      activeRoute,
      isHybridModel,
      routingEnum,
      routerLinks,
      extraRouterLinks,
    }
  },
}

export default HeaderInternalLink
