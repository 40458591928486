/* global moment */

const FinancingCalculation = {
  calculation (deposit, period, price, interestRate, residualPercent, processingCost, withEom, paymentsPerYear = 12, instantFirstPayment = true) {
    const interestRateFloat = interestRate / 100
    const depositValue = Math.round(deposit / 100 * price * 100) / 100
    const financingValue = price - depositValue
    const residualValue = Math.round(residualPercent / 100 * price * 100) / 100

    const monthlyPayment = Math.max(0, Math.round(this.monthlyPayment(
      financingValue,
      period,
      interestRateFloat / paymentsPerYear,
      residualValue,
    ) * 100) / 100)
    const totalCost = (monthlyPayment * period) + processingCost + residualValue

    let eom = null
    if (withEom) {
      const values = [financingValue * -1, processingCost]
      const dateVariable = moment().date(1)
      const dates = [dateVariable.toISOString(), dateVariable.toISOString()]
      if (instantFirstPayment) dates.push(dateVariable.toISOString())
      const momentPeriodString = paymentsPerYear === 1
        ? 'years'
        : paymentsPerYear === 4 || paymentsPerYear === 2
          ? 'quarters'
          : paymentsPerYear === 12
            ? 'months'
            : 'error'
      if (momentPeriodString === 'error') throw new Error('FinancingCalculation paymentsPerYear has unknown value')

      for (let ii = 0; ii < period; ii++) {
        values.push(Math.abs(monthlyPayment))
        if (ii) {
          dates.push(dateVariable.toISOString())
        }
        dateVariable.add(paymentsPerYear === 2 ? 2 : 1, momentPeriodString)
      }
      if (!instantFirstPayment) dates.push(dateVariable.toISOString())
      if (residualValue) {
        values.push(residualValue)
        dates.push(dateVariable.toISOString())
      }

      eom = this.xirr(values, dates, 0.1) * 100
    }

    return {
      depositValue,
      monthlyPayment,
      financingValue,
      interestRate,
      residualValue,
      eom,
      totalCost,
      processingCost,
    }
  },
  // ****************************************************************************
  //                   LOAN * ((1 + INTEREST) ^ TOTALPAYMENTS) - residualValue
  // PMT = INTEREST * -----------------------------------------------------------
  //                    ((1 + INTEREST) ^ TOTALPAYMENTS) - 1
  // ****************************************************************************
  monthlyPayment (loanAmount, totalPayments, interestPerPeriod, residualValue) {
    if (interestPerPeriod === 0) {
      return (loanAmount - residualValue) / totalPayments
    }

    const poweredInterest = Math.pow(1 + interestPerPeriod, totalPayments)
    const denominator = loanAmount * poweredInterest - residualValue
    const counter = poweredInterest - 1
    return interestPerPeriod * (denominator / counter)
  },
  // Internal Rate of Return
  xirr (values, dates, guessParam) {
    // Credits: algorithm inspired by Apache OpenOffice

    // Calculates the resulting amount
    const irrResult = (values, dates, rate) => {
      const r = rate + 1
      let result = values[0]
      for (let i = 1; i < values.length; i++) {
        result += values[i] / Math.pow(r, moment(dates[i]).diff(moment(dates[0]), 'days', true) / 365)
      }
      return result
    }

    // Calculates the first derivation
    const irrResultDeriv = (values, dates, rate) => {
      const r = rate + 1
      let result = 0
      for (let i = 1; i < values.length; i++) {
        const frac = moment(dates[i]).diff(moment(dates[0]), 'days', true) / 365
        result -= frac * values[i] / Math.pow(r, frac + 1)
      }
      return result
    }

    // Check that values contains at least one positive value and one negative value
    let positive = false
    let negative = false
    for (let i = 0; i < values.length; i++) {
      if (values[i] > 0) positive = true
      if (values[i] < 0) negative = true
    }

    // Return error if values does not contain at least one positive value and one negative value
    if (!positive || !negative) return '#NUM!'

    // Initialize guess and resultRate
    const guess = (typeof guessParam === 'undefined') ? 0.1 : guessParam
    let resultRate = guess

    // Set maximum epsilon for end of iteration
    const epsMax = 1e-10

    // Set maximum number of iterations
    const iterMax = 50

    // Implement Newton's method
    let newRate, epsRate, resultValue
    let iteration = 0
    let contLoop = true
    do {
      resultValue = irrResult(values, dates, resultRate)
      newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate)
      epsRate = Math.abs(newRate - resultRate)
      resultRate = newRate
      contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax)
    } while (contLoop && (++iteration < iterMax))

    if (contLoop) return '#NUM!'

    // Return internal rate of return
    return resultRate
  },
}

export default FinancingCalculation
