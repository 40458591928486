/* global Vue */

// vue2 source: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
// migrated to vue3
const TransitionCollapse = {
  name: 'TransitionCollapse',
  functional: true,
  render () {
    const data = {
      props: {
        name: {
          type: String,
          default: 'collapse',
        },
      },
      onEnter (element) {
        element.style.height = 'auto'

        const height = getComputedStyle(element).height
        element.style.height = 0

        // Force repaint to make sure the animation is triggered correctly.
        // eslint-disable-next-line no-unused-expressions
        getComputedStyle(element).height

        requestAnimationFrame(() => {
          element.style.height = height
        })
      },
      onAfterEnter (element) {
        element.style.height = 'auto'
      },
      onLeave (element) {
        // Force repaint to make sure the animation is triggered correctly.
        element.style.height = getComputedStyle(element).height

        requestAnimationFrame(() => {
          element.style.height = 0
        })
      },
    }

    return Vue.h(Vue.Transition, data, this.$slots)
  },
}

export default TransitionCollapse
