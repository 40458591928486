import { RoutingEnum } from '../enums/RoutingEnum'

const CalculationDefaultValues = {
  DEPOSIT_DEFAULT: 20,
  DEPOSIT_FINANCING_44: 25,
  DEPOSIT_FINANCING_ZERO: 40,
  DEPOSIT_FINANCING_718: 48,
  DEPOSIT: (financingType) => {
    switch (financingType) {
      case RoutingEnum.FINANCING_TYPE_ZERO:
        return CalculationDefaultValues.DEPOSIT_FINANCING_ZERO
      case RoutingEnum.FINANCING_44:
        return CalculationDefaultValues.DEPOSIT_FINANCING_44
      case RoutingEnum.FINANCING_718:
        return CalculationDefaultValues.DEPOSIT_FINANCING_718
      default:
        return CalculationDefaultValues.DEPOSIT_DEFAULT
    }
  },
  PERIOD_DEFAULT: 84,
  PERIOD_ZERO: 48,
  PERIOD_FINANCING_44: 36,
  PERIOD_718: 84,
  MEGAMETER: 10,
  PERIOD: (financingType) => {
    switch (financingType) {
      case RoutingEnum.FINANCING_TYPE_ZERO:
        return CalculationDefaultValues.PERIOD_ZERO
      case RoutingEnum.FINANCING_44:
        return CalculationDefaultValues.PERIOD_FINANCING_44
      case RoutingEnum.FINANCING_718:
        return CalculationDefaultValues.PERIOD_718
      default:
        return CalculationDefaultValues.PERIOD_DEFAULT
    }
  },
}

export default CalculationDefaultValues
