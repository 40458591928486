const InfoIcon = {
  template: '<i class="info-icon" @click="showInfo()"></i>',
  props: {
    slug: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showInfo () {
      const text = (window.INFO_ICONS[this.slug] || '')
        .replace('%s', this.$filters.formatNumber(this.value))
      this.$toast.default(text, { position: 'top-right', duration: 9000 })
    },
  },
}

export default InfoIcon
