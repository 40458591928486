const Cookies = {
  template: `
  <div class="container header-padding">
    <h2 id="smernice-za-piškotke">Smernice za piškotke</h2>
    <p>Zaščita vaše zasebnosti pri obisku naše spletne strani nam je posebno pomembna.
      Prav zato boste v nadaljevanju našli podrobne informacije o zbiranju osebnih in neosebnih podatkov.
      Podjetju Porsche Inter Auto d.o.o pripada pravica do uporabe osebnih in anonimnih podatkov ob upoštevanju v nadaljevanju opisanih pravic posameznikov v zakonsko dopustnem obsegu.</p>
    <p>Na tej spletni strani se uporablja programska oprema za analizo obiska spletnih strani. Z analizo teh podatkov lahko pridobimo pomembne rezultate o zahtevah uporabnikov. Ti rezultati prispevajo k nadaljnjemu izboljšanju kakovosti ponudbe. Ob vsakem odpiranju nove spletne strani oz. datoteke se shrani naslednji sklop podatkov:</p>
    <ul>
      <li>anonimizirana oblika IP-naslova računalnika, ki pošilja zahtevo</li>
      <li>datum in čas pošiljanja zahteve</li><li>ime strani oz. datoteke, ki jo želite priklicati</li>
      <li>ID sejereferrer-URL (izvorni URL, s katerega ste prišli na našo stran)</li>
      <li>količino prenesenih podatkov</li><li>informacije o uporabljenem brskalniku (produkt in verzija)</li>
      <li>operacijski sistem uporabnika</li>
    </ul>
    <p><strong>Za varstvo osebnih podatkov je odgovorna družba PORSCHE INTER AUTO d.o.o. trgovina na debelo in drobno, Bravničarjeva ulica 5, 1000 Ljubljana, davčna številka: SI76104672, matična številka: 1453831000.</strong></p>
    <p><strong>1. Kaj je piškotek?</strong></p>
    <p>Piškotek je majhna tekstovna datoteka, ki shranjuje spletne nastavitve. Skoraj vsaka spletna stran uporablja to tehnologijo. Piškotki se prenesejo, ko vaš spletni brskalnik prvič obišče neko spletno stran. Ob naslednjem prikazu te spletne strani z isto napravo se piškotek in ustrezno shranjene informacije posredujejo spletni strani, ki ste jo prikazali (lastni piškotek) ali drugi spletni strani, kateri ta piškotek pripada (piškotek tretjih oseb). Tako spletna stran prepozna, da ste jo s tem brskalnikom že prikazali, ter tako v številnih primerih prilagodi prikazano vsebino.</p>
    <p>Nekateri piškotki so izjemno uporabni, saj lahko izboljšajo uporabniško izkušnjo ob ponovnem prikazu spletne strani, ki ste jo že večkrat obiskali. Piškotki se npr. spomnijo vaših interesov, sporočajo, kako uporabljate določeno spletno stran, in relevantno prilagodijo prikazane ponudbe vašim osebnim interesom in potrebam, pod pogojem, da uporabljate isto končno napravo in isti brskalnik kot doslej. Večina uporabljenih piškotkov se po zaključku brskanja samodejno izbriše s trdega diska (t. I. »sejni piškotki«). Uporabljamo pa tudi piškotke, ki ostanejo na vašem trdem disku tudi po zaključku seje brskanja. Tako se izboljšuje uporabniška izkušnja ob ponovnem prikazu spletne strani, saj se spletna stran prilagaja vašim osebnim potrebam, prav tako pa se optimizira tudi čas nalaganja strani.</p>
    <p><strong>2. Nastavitve piškotkov na tej spletni strani</strong></p>
    <p><strong>2.1. Piškotki brez privolitve</strong></p>
    <p>Nujno potrebni piškotki zagotavljajo funkcije, brez katerih te spletne strani ne morete uporabljati tako, kot je nameravano. Te piškotke uporabljamo izključno mi in se zato imenujejo »lastni piškotki«. Na vaš računalnik se shranjujejo samo med aktualno sejo brskanja. Nujno potrebni piškotki: Za prijavo uporabljamo t. i. »sejni piškotek«. Brez tega piškotka se ni možno prijaviti in s tem tudi ni funkcij za prijavo.</p>
    <p>Obiskovalec lahko piškoti nastavi na dnu te strani.</p>
    <p>Takšni piškotki npr. pri menjavi spletne strani po potrebi nadalje zagotavljajo funkcijo menjave od spletne strani http na https in s tem upoštevanje povečanih varnostnih zahtev glede posredovanja podatkov. Za uporabo nujno potrebnih piškotkov privolitev ni potrebna. Nujno potrebnih piškotkov ni mogoče deaktivirati prek funkcije te spletne strani. Lahko pa jih kadar koli deaktivirate prek uporabljenega brskalnika. V zvezi s tem glejte tudi navodilo spodaj.</p>
    <p><strong>2.2. Lastni piškotki s privolitvijo</strong></p>
    <p>Piškotki, ki po čisto pravni definiciji niso nujno potrebni za uporabljanje spletne strani, vseeno izpolnjujejo pomembne naloge. Funkcije, ki omogočajo udobno brskanje po naši spletni strani, npr. predizpolnjeni obrazci, brez teh piškotkov niso več na voljo. Dodanih nastavitev, npr. izbiro jezika, ni mogoče shraniti in jih je zato treba ponovno preveriti na vsaki spletni strani. Nadalje nimamo več nobene možnosti, da vam prikažemo individualno prilagojene ponudbe.</p>
    <p><strong>2.3. Piškotki tretjih oseb s privolitvijo</strong></p>
    <p>Na tej spletni strani so integrirane tudi vsebine tretjih ponudnikov. Ti lahko nastavljajo piškotke, ki jim javijo, da ste obiskali našo spletno stran. Za več informacij o uporabi teh piškotkov obiščite spletne strani teh tretjih ponudnikov.</p>
    <p><strong>Vtičniki za družbena omrežja</strong></p>
    <p>Na spletni strani <a href="https://financiranje.porsche.si/">financiranje.porsche.si</a> se uporabljajo tudi t.i. vtičniki za družbena omrežja (v nadaljevanju gumbe), kot so Facebook, Twitter in Google+. Pri vašem obisku na naši spletni strani so ti gumbi privzeto deaktivirani, to pomeni, da posameznemu družbenemu omrežju brez vašega posredovanja ne pošiljate nobenih podatkov. Preden lahko začnete uporabljati gumbe, jih morate s klikom aktivno aktivirati. Gumb ostane tako dolgo aktiven, dokler ga ponovno ne deaktivirate ali izbrišete vaših piškotkov.</p>
    <p>Po aktiviranju se vzpostavi neposredna povezava s strežnikom posameznega družbenega omrežja. Vsebina gumba se nato prenese iz družbenih omrežij neposredno na vaš brskalnik in iz tega vključi v spletno stran. Po aktiviranju gumba lahko posamezno družbeno omrežje že zbira podatke, neodvisno od tega, če vplivate na gumb. Če ste prijavljeni v družbeno omrežje, lahko to vaš obisk te spletne strani dodeli vašemu uporabniškemu računu. Družbeno omrežje ne more dodeliti obiska druge spletne strani, dokler tudi tam ne aktivirate posameznega gumba. Če ste član družbenega omrežja in ne želite, da ta pri vašem obisku naše spletne strani poveže zbrane podatke z vašimi shranjenimi podatki o članstvu, se morate pred aktiviranjem gumba odjaviti iz posameznega družbenega omrežja. Na obseg podatkov, ki jih družbena omrežja zbirajo z vašimi gumbi, nimamo nobenega vpliva. Več informacij o namenu in obsegu zbiranja podatkov ter o nadaljnji obdelavi in uporabi podatkov s strani posameznih družbenih omrežij ter o svojih pravicah v zvezi s tem in možnostih nastavitev za varovanje svoje zasebnosti si preberite v politiki zasebnosti posameznih družbenih omrežij.</p>
    <p><strong>Vaše nastavitve piškotkov</strong></p>
    <p>Svoj brskalnik lahko nastavite tako, da sprejemate ali na splošno zavračate piškotke le s svojo izrecno privolitvijo. Opozarjamo vas, da so določeni segmenti spletne strani brez uporabe piškotkov omejeni ali popolnoma neuporabni. Sami lahko upravljate z uporabo piškotkov in to preprečite tako, da svoj brskalnik konfigurirate, kot sledi:</p>
    <ul>
      <li>Internet Explorer, glejte tukaj (<a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies</a>) Orodja – Internetne možnosti – Dodatno – pod točko Varnost odkljukajte in potrdite »Spletnim mestom, ki jih odprem v Internet Explorerju, pošlji zahtevo »Do Not Track« – potrdite.</li>
      <li>Firefox, glejte tukaj (<a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>) Orodja – Možnosti – Zasebnost in varnost, pod Zgodovina nastavite »Uporabite nastavitve po meri« in pri piškotkih izberite ustrezne nastavitve – potrdite.</li>
      <li>Google Chrome, glejte tukaj: (<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop</a>) Meni – Nastavitve – Prikaži dodatne nastavitve – pod zavihkom »Zasebnost« kliknite »Nastavitve vsebine« in pri piškotkih odkljukajte želene nastavitve – potrdite.</li>
      <li>Safari, glejte tukaj (<a href="https://support.apple.com/kb/ph21411">https://support.apple.com/kb/ph21411</a>) Safari – Preferences – Privacy – pod »Block Cookies« izberite želene nastavitve – potrdite.</li>
    </ul>
    <p><strong>Splošne informacije o varstvu osebnih podatkov</strong></p>
    <p>V sodobni družbi je pravica do zasebnosti ena najpomembnejših človekovih pravic. V Porsche Inter Auto d.o.o. se zavedamo pomena varstva osebnih podatkov, zato z vašimi podatki ravnamo skladno s predpisi, ki urejajo varstvo osebnih podatkov. Porsche Inter Auto kot upravljavec osebnih podatkov posameznikom omogoča, da so seznanjeni z vsemi relevantnimi informacijami, ki se nanašajo na njihove osebne podatke.</p>
    <p>Za varstvo osebnih podatkov je odgovorna družba PORSCHE INTER AUTO d.o.o. trgovina na debelo in drobno, Bravničarjeva ulica 5, 1000 Ljubljana, davčna številka: SI76104672, matična številka: 1453831000.</p>
    <p>Za varstvo osebnih podatkov so odgovorni tudi pooblaščeni partnerji, ki skupaj z nami zagotavljajo celostno oskrbo z originalnimi rezervnimi deli in avtomobilsko opremo ter opravljajo široko paleto servisnih storitev.</p>
    <p>Porsche Inter Auto d.o.o. osebne podatke hrani in varuje, tako da ne prihaja do morebitnih neupravičenih razkritij podatkov nepooblaščenim osebam. Pri Porsche Inter Auto d.o.o. se zavezujemo, da osebnih podatkov ne bomo posredovali, posodili ali prodali tretji osebi brez predhodnega obvestila in pridobitve vaše privolitve, brez ustreznih primernih zaščitnih ukrepov, ter da bomo osebne podatke obdelovali zgolj v okviru zakonitih pravnih podlag in izbranih namenov.</p>
    <p>Porsche Inter Auto d.o.o. zbranih osebnih podatkov ne prenaša oz. ne posreduje v tretje države.</p>
    <p><strong>Oseba, odgovorna za varstvo podatkov</strong></p>
    <p>Oseba, odgovorna za varstvo podatkov, je dosegljiva na elektronskem naslovu <a href="mailto:dpo.pia@porsche.si">dpo.pia@porsche.si</a>.</p>
    <p><strong>Katere vrste osebnih podatkov zbiramo in obdelujemo</strong></p>
    <p>V okviru opravljanja svoje dejavnosti v Porsche Inter Auto zbiramo in obdelujemo naslednje vrste osebnih podatkov: ime in priimek, datum rojstva, kontaktni podatki (naslov, elektronski naslov, telefonska številka), podatki o vozilu (na primer znamka in tip, št. šasije, karoserija, številka motorja, menjalnik, teža, registrska številka vozila, prva registracija, letnik, število prevoženih kilometrov, emisijski razred, itn.), spletni piškotki, geolokacijski podatki posameznika, podatki socialnih omrežij ter podatki o kanalih in aplikacijah, ki jih posameznik uporablja.</p>
    <p>Porsche Inter Auto d.o.o vodi evidence dejavnosti obdelave osebnih podatkov, v okviru katerih so razvidne vrste osebnih podatkov ter nameni njihove obdelave.</p>
    <p><strong>Pravne podlage obdelave osebnih podatkov in nameni obdelave</strong></p>
    <ol>
      <li>Obdelava osebnih podatkov za potrebe izvajanja storitev</li>
    </ol>
    <p>Porsche Inter Auto d.o.o obdeluje zbrane osebne podatke posameznikov za namene izvajanja storitev ter za spremljanje zadovoljstva strank (vključno z reklamacijami) in vodenja kontaktov s posameznikom prek različnih kanalov.</p>
    <ol start="2">
      <li>Obdelava osebnih podatkov na podlagi privolitve stranke</li>
    </ol>
    <p>Obdelava osebnih podatkov lahko temelji tudi na konkretni privolitvi posameznika, ki Porsche Inter Auto d.o.o omogoča uporabo njegovih osebnih podatkov za namene, opredeljene v privolitvi, in sicer za:</p>
    <ul>
      <li>prejemanje splošnih novic, in sicer o novostih, ugodnostih, ponudbah, vabila na dogodke, vabila za sodelovanje v nagradnih igrah ter vabila za sodelovanje pri anketiranju in raziskavah,</li>
      <li>prejemanje prilagojenih ponudb naših produktov in storitev ter ostalih novosti, ugodnosti, vabila na dogodke, vabila za sodelovanje v nagradnih igrah in vabila na servise, in sicer za izvajanje segmentacije,</li>
      <li>prejemanje osebno prilagojenih ponudb, in sicer bo Porsche Inter Auto d.o.o na osnovi individualne analize nakupovalnih navad, preteklih nakupov, vaših zanimanj in osebnih lastnosti (zlasti analize in vrednotenja, odpiranja elektronskih oglasov, klikov na ponujene linke, slikovnega materiala) in anketiranja oblikovali vaš osebnostni profil in temu primerno oblikovali ponudbo storitev za vas.</li>
    </ul>
    <p>V primeru, da posameznik ne poda privolitve za izvajanje zgoraj naštetih namenov obdelave osebnih podatkov, ga poda delno ali soglasje (delno) prekliče, bo Porsche Inter Auto d.o.o takšnega posameznika obveščala le v primerih in v obsegu dane privolitve oziroma na načine, ki jih dovoljuje veljavna zakonodaja (npr. splošno obveščanje, izpolnjevanje obveznosti glede obveščanja o produktu oz. storitvi, ki jo stranka uporablja).</p>
    <p>Zagotavljanje storitev ni pogojena s podajo privolitve. Podaja privolitve je prostovoljna in v kolikor se posameznik odloči, da je ne želi podati oz. jo kasneje prekliče, to v nobenem primeru ne zmanjšuje pravic, izhajajoč iz poslovnega razmerja oz. za posameznika to ne predstavlja dodatnih stroškov oz. oteževalnih okoliščin.</p>
    <p>Porsche Inter Auto d.o.o lahko podatke posameznikov, če so ti podali privolitev za namene, opredeljene v privolitvi, posreduje tudi drugim družbam Skupine Porsche.</p>
    <p><strong>Preklic privolitve posameznika</strong></p>
    <p>Privolitev je dana za namene opredeljene v privolitvi in velja do preklica. Ko posameznik v delu ali v celoti prekliče svojo privolitev, Porsche Inter Auto njegovih podatkov za ta namene, za katere je podal preklic, ne bo več uporabljala.</p>
    <p>Če želi posameznik preklicati privolitev, to lahko stori tako, da preklic pošlje Porsche Inter Auto ali pooblaščenemu partnerju prek spleta na <a href="mailto:dpo.pia@porsche.si">dpo.pia@porsche.si</a> ali v pisni obliki na naslov Porsche Inter Auto ali pooblaščenemu partnerju. Porsche Inter Auto d.o.o in pooblaščeni partnerji bodo drug drugega obvestili o preklicu. Preklic privolitve ne vpliva na zakonitost obdelave osebnih podatkov na podlagi privolitve pred njenim preklicem.</p>
    <p>Posamezniki se lahko vedno »odjavijo« od prejemanja posameznih sporočil Porsche Inter Auto d.o.o in pooblaščenih partnerjev tudi preko komunikacijskega medija, na katerega so prejele določeno sporočilo. Povezava do odjave oz. informacija, kako odjavo realizirati, je v sporočilu. Zahteva posameznika po preklicu se bo zabeležila v sistemu in se začela uveljavljati nemudoma oziroma najkasneje 15 dni po prejemu.</p>
    <p><strong>Kategorije uporabnikov osebnih podatkov</strong></p>
    <p>Uporabniki osebnih podatkov so uslužbenci, pooblaščeni partnerji in pogodbeni obdelovalcev Porsche Inter Auto d.o.o, ki so na podlagi delovnopravne zakonodaje, pogodb o obdelavi osebnih podatkov ali pogodb o varovanju zaupnih podatkov zavezani k spoštovanju in varovanju osebnih podatkov posameznikov. Podrobnejše informacije o kategorijah uporabnikov, pogodbenih partnerjih in pogodbenih obdelovalcih lahko pridobite na zahtevo pri <a href="mailto:dpo.pia@porsche.si">dpo.pia@porsche.si</a>.</p>
    <p><strong>Uporabniki do podatkov dostopajo v skladu z dodeljenimi pravicami dostopa do podatkov.</strong></p>
    <p>Porsche Inter Auto osebne podatke posreduje tudi tretjim osebam, če takšno obveznost posredovanja oziroma razkritja nalaga zakon (na primer Finančna uprava Republike Slovenije, sodišča, policija ipd.).</p>
    <p><strong>Hramba osebnih podatkov</strong></p>
    <p>Obdobje hrambe osebnih podatkov je odvisno od podlage obdelave in namena obdelave posamezne kategorije osebnih podatkov. Osebni podatki se hranijo le toliko časa, kolikor je to potrebno za dosego namena, zaradi katerega so se zbirali ali nadalje obdelovali. Osebni podatki se po izpolnitvi namena obdelav, če ne obstaja druga pravna podlaga ali če je to potrebno za uveljavljanje, izvajanje ali obrambo pravnih zahtevkov, zbrišejo, uničijo, blokirajo ali anonimizirajo.</p>
    <p><strong>Pravice strank</strong></p>
    <p>Porsche Inter Auto d.o.o zagotovi posameznikom, katerih osebne podatke obdeluje, možnost uresničevanja svojih pravic, in sicer:</p>
    <ul>
      <li>pravice dostopa do osebnih podatkov, ki so bili zbrani v zvezi z njim,</li>
      <li>pravice do popravka, da Porsche Inter Auto d.o.o brez nepotrebnega odlašanja popravi netočne osebne podatke v zvezi z njim.</li>
      <li>Pravice do izbrisa, da Porsche Inter Auto d.o.o. brez nepotrebnega odlašanja izbriše osebne podatke v zvezi z njim, kadar velja eden od naslednjih razlogov: (1) osebni podatki niso več potrebni v namene, za katere so bili zbrani ali kako drugače obdelani, (2) posameznik, prekliče privolitev, na podlagi katere poteka obdelava in kadar za obdelavo ne obstaja nobena druga pravna podlaga, (3) posameznik, obdelavi ugovarja, za njihovo obdelavo pa ne obstajajo nobeni prevladujoči zakoniti razlogi, (4) osebni podatki so bili obdelani nezakonito, (5) če tako določa zakon.</li>
      <li>Pravice do omejitve obdelave, kadar velja eden od naslednjih primerov: (1) posameznik oporeka točnosti podatkov, in sicer za obdobje, ki Porsche Inter Auto omogoča preveriti točnost osebnih podatkov, (2) je obdelava nezakonita in posameznik nasprotuje izbrisu osebnih podatkov, ter namesto tega zahteva omejitev njihove uporabe, (3) Porsche Inter Auto osebnih podatkov ne potrebuje več za namene obdelave, temveč jih posameznik potrebuje za uveljavljanje, izvajanje ali obrambo pravnih zahtevkov, (4) je posameznik vložil ugovor v zvezi z obdelavo podatkov, dokler se ne preveri, ali zakoniti razlogi upravljavca prevladajo nad razlogi posameznika. Kadar je bila obdelava osebnih podatkov omejena v skladu s prejšnjim odstavkom, se taki osebni podatki z izjemo njihovega shranjevanja obdelujejo le s privolitvijo posameznika, na katerega se ti nanašajo, ali za uveljavljanje, izvajanje ali obrambo pravnih zahtevkov ali zaradi varstva pravic druge fizične ali pravne osebe. Porsche Inter Auto mora pred preklicem omejitve obdelave o tem obvestiti posameznika.</li>
    </ul>
    <p>Posameznik lahko vloži zahtevo na način, ki omogoča njegovo identifikacijo in sicer z ustno zahtevo na zapisnik v poslovalnici, pisna zahtevo ali zahtevo oddano preko elektronske pošte.</p>
    <p>Prejete zahteve obravnava oseba odgovorna za varstvo podatkov. Porsche Inter Auto je dolžna na zahtevo posameznika odgovoriti brez nepotrebnega odlašanja oziroma najpozneje v enem mesecu.</p>
    <p>Porsche Inter Auto mora zahtevane informacije posredovati posamezniku brezplačno. Kadar pa so zahteve posameznika, očitno neutemeljene ali pretirane zlasti, ker se ponavljajo, lahko Porsche Inter Auto (1) zaračuna razumno pristojbino, pri čemer upošteva administrativne stroške posredovanja informacij ali sporočila ali izvajanje zahtevanega ukrepa ali (2) zavrne ukrepanje v zvezi z zahtevo.</p>
    <p>Ugovor v primeru nepopolne odločitve upravljavca in pravica do vložitve pritožbe pri nadzornem organu</p>
    <p>Če posameznik meni, da osebni podatki, ki jih je prejel na podlagi zahteve za uveljavitev svojih pravic iz prejšnje točke teh Splošnih informacij, niso osebni podatki, ki jih je zahteval ali da ni prejel vseh zahtevanih osebnih podatkov, lahko pred vložitvijo pritožbe pri Porsche Inter Auto vloži obrazložen ugovor v roku 15 dni. Porsche Inter Auto je dolžan o ugovoru odločiti v 5 delovnih dneh.</p>
    <p>Zoper molk upravljavca (če torej Porsche Inter Auto posamezniku na njegovo zahtevo za uveljavljanje pravic ne odgovori v roku 1 meseca), lahko posameznik pri nadzornem organu vloži pritožbo zaradi molka.</p>
    <p>Zoper zavrnilni odgovor upravljavca je možna pritožba, za reševanje katere je pristojen nadzorni organ. Posameznik lahko pritožbo vloži pri upravljavcu v 15 dneh od prejema odgovora upravljavca.</p>
    <p><strong>Drugo</strong></p>
    <p>Porsche Inter Auto si pridružuje pravico do sprememb ali dopolnitev teh Splošnih informacij za zagotavljanje skladnosti s predpisi s področja varstva osebnih podatkov. Informacije so na voljo na naši spletni strani.</p>
    <p>Podrobnejše informacije lahko pridobite na zahtevo tudi pri osebi odgovorni za varstvo podatkov na elektronskem naslovu <a href="mailto:dpo.pia@porsche.si">dpo.pia@porsche.si</a>.</p>
    <p><strong>Vaše nastavitve piškotkov</strong></p>

    <div id="ot-sdk-cookie-policy"></div>
    <button class="ot-sdk-show-settings button">Nastavitve piškotkov</button>
  </div>`,
}

export default Cookies
