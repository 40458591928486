/* global Vue, VueRouter, Swiper, SwiperSlide */

import ButtonIconForwardSolo from '../partials/ButtonIconForwardSolo'
import { RoutingEnum } from '../enums/RoutingEnum'
import { Navigation, Pagination } from 'swiper'

const Hero = {
  template: `
<div class="hero header-padding">
  <div class="bg-grey-gradient"></div>
  <div class="container">
    <div class="hero__container">
      <div class="hero__image">

        <div class="swiper" v-show="image">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
          </div>
        </div>

        <div v-show="!image" class="swiper">
          <Swiper
            v-if="initSwiper"
            :loop="swiperLoop"
            :modules="swiperModules"
            :navigation="sliderNavigation"
            :pagination="sliderPagination"
            @slideChange="onSlideChange">
              <SwiperSlide v-for="page in pages">
                <div
                  class="swiper-slide"
                  :style="{ backgroundImage: 'url(' + page.image + ')' }"></div>
              </SwiperSlide>
          </Swiper>
          <div class="swiper-pagination is-hidden-md-up hero__pagination" ref="swiperPaginationEl"></div>
        </div>
      </div>

      <div class="hero__description">
        <h4 class="hero__subtitle">{{ propOrPageParameter('subtitle') }}</h4>
        <h1 class="hero__title">{{ propOrPageParameter('title') }}</h1>

        <p class="text-body" v-html="propOrPageParameter('description')"></p>

        <a class="color-default" v-show="hrefLink" :href="hrefLink" download>
          <div class="button-group">
            <button class="button" :id="hrefAttrId"><span>{{ propOrPageParameter('cta') }}</span></button>
            <div class="button__icon-arrow" :id="hrefAttrId +'P'" :class="{down: ctaArrowDown}"></div>
          </div>
        </a>
        <div class="button-group" @click="ctaClick()" v-show="!hrefLink">
          <button class="button"><span>{{ propOrPageParameter('cta') }}</span></button>
          <div class="button__icon-arrow" :class="{down: ctaArrowDown}"></div>
        </div>

        <div class="hero__slider-icons is-hidden-sm-down" v-show="!heroImageSingle">
          <div class="hero__slider-icon left" ref="sliderIconLeftEl"></div>
          <div class="hero__slider-icon right" ref="sliderIconRightEl"></div>
        </div>

        <div class="hero__slide-down" @click="slideDown()">
          <span>{{ slideDownText }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div ref="bottomEl"></div>
  `,
  components: { ButtonIconForwardSolo, Swiper, SwiperSlide },
  props: {
    pages: {
      type: Array,
      default: [],
    },
    image: {
      type: String,
      default: '',
    },
    routingToDefault: {
      type: Boolean,
      default: false,
    },
    hrefLink: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    cta: {
      type: String,
      default: '',
    },
    slideDownText: {
      type: String,
      default: '',
    },
    ctaArrowDown: {
      type: Boolean,
      default: false,
    },
    hrefAttrId: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const heroImageSingle = props.pages.length < 2
    const router = VueRouter.useRouter()
    const sliderIconRightEl = Vue.ref(null)
    const sliderIconLeftEl = Vue.ref(null)
    const swiperPaginationEl = Vue.ref(null)
    const sliderNavigation = Vue.ref(null)
    const sliderPagination = Vue.ref({
      el: null,
      clickable: true,
      bulletActiveClass: 'active',
    })
    const swiperLoop = Vue.ref(false)
    const initSwiper = Vue.ref(false)
    const bottomEl = Vue.ref(null)
    const pageDetails = Vue.ref({})
    const slideDown = () => {
      bottomEl.value.scrollIntoView({ behavior: 'smooth' })
    }
    const ctaClick = () => {
      if (props.routingToDefault) {
        router.push({
          name: RoutingEnum.ROUTE_LISTING,
          params: { financing_type: RoutingEnum.ALL_FINANCING_TYPES, model: RoutingEnum.ALL_MODELS },
        })
      }
    }

    const assignPage = (page) => {
      pageDetails.value = page
    }
    const propOrPageParameter = (parameter) => {
      return props[parameter] || pageDetails.value[parameter]
    }

    const onSlideChange = (swiper) => {
      assignPage(props.pages[Math.abs(swiper.activeIndex - 1) % props.pages.length])
    }

    Vue.onMounted(() => {
      if (props.pages.length && heroImageSingle) assignPage(props.pages[0])

      if (props.pages.length) {
        swiperLoop.value = props.pages.length > 1
        sliderNavigation.value = {
          nextEl: sliderIconRightEl.value,
          prevEl: sliderIconLeftEl.value,
        }
        sliderPagination.value.el = swiperPaginationEl.value
        // swiper doesn't have reactive settings, is hidden behind v-if until element refs are available
        initSwiper.value = true
      }
    })

    return {
      heroImageSingle,
      sliderIconRightEl,
      sliderIconLeftEl,
      swiperPaginationEl,
      sliderNavigation,
      sliderPagination,
      swiperLoop,
      swiperModules: [Navigation, Pagination],
      initSwiper,
      bottomEl,
      slideDown,
      ctaClick,
      propOrPageParameter,
      onSlideChange,
    }
  },
}

export default Hero
