/* global Vue, moment */

import SliderPeriodValues from '../helpers/SliderPeriodValues'
import SliderDepositValues from '../helpers/SliderDepositValues'
import InfoIcon from './InfoIcon'
import { RoutingEnum } from '../enums/RoutingEnum'

const DetailCalculation = {
  template: `
<div class="detail-calculation">
  <div class="container" v-show="showAllSliders">
    <p class="detail-calculation__title">{{ sliderInstructions }}</p>
    <div class="detail-calculation__sliders" :class="{'period-first': isFinancingZero}">
      <div class="detail-calculation__slider">
        <div class="detail-calculation__slider-title">
          <span>{{ contentData.calculation_deposit }}: {{ $filters.formatNumber(financingValues.depositValue) }}</span>
        </div>
        <div class="detail-calculation__slider-slider">
          <el-slider
            v-model="depositValue"
            v-bind="depositSliderOptions"
            @change="changedSliders" />
        </div>
      </div>
      <div class="detail-calculation__slider">
        <div class="detail-calculation__slider-title">
          <span>{{ contentData.calculation_period }}</span>
        </div>
        <div class="detail-calculation__slider-slider">
          <el-slider
            v-model="periodValue"
            v-bind="periodSliderOptions"
            @change="changedSliders" />
        </div>
      </div>
      <div class="detail-calculation__slider" v-show="showKmSlider">
        <div class="detail-calculation__slider-title">
          <span>{{ contentData.calculation_km }}</span>
        </div>
        <div class="detail-calculation__slider-slider">
          <el-slider
            v-model="kmValue"
            v-bind="kmSliderOptions"
            @change="changedSliders" />
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <p class="detail-calculation__title" v-show="isFinancing44">{{ contentData.static_calculation_title }}</p>
    <div class="detail-calculation__numbers grid" v-show="isFinancing44">
      <div class="col-12 col-sm-3 detail-calculation__number highlight">
        <span class="h3">{{ $filters.formatNumber(financingValues.depositValue) }} €</span>
        <span>
          {{ contentData.calculation_static_values.deposit }}
         </span>
      </div>
      <div class="col-12 col-sm-3 detail-calculation__number highlight" v-for="ration in staticRationsArray">
        <span class="h3">{{ $filters.formatNumber(financingValues.monthlyPayment) }} €</span>
        <span>
          {{ rationText(ration) }}
          <InfoIcon :slug="infoIconTexts.yearlyPayment" :value="financingValues.monthlyPayment" />
          <span v-show="ration > 1">&nbsp; {{ contentData.calculation_static_values.garantie }}</span>
         </span>
      </div>
    </div>

    <div class="detail-calculation__numbers grid">
      <div class="col-12 col-sm-4 detail-calculation__number highlight" v-show="!isFinancing44">
        <span class="h3">{{ $filters.formatNumber(financingValues.monthlyPayment) }} €</span>
        <span>
          {{ contentData.calculation_values.monthly }}
           <InfoIcon :slug="infoIconTexts.monthlyPayment" :value="financingValues.monthlyPayment" />
         </span>
      </div>
      <div class="col-12 col-sm-4 detail-calculation__number">
        <span class="h3">{{ $filters.formatNumber(financingValues.financingValue) }} €</span>
        <span>{{ contentData.calculation_values.financing_total }} <InfoIcon :slug="infoIconTexts.financingValue" /></span>
      </div>
      <div class="col-12 col-sm-4 detail-calculation__number">
        <span class="h3">{{ $filters.formatNumber(financingValues.interestRate) }}%</span>
        <span>{{ contentData.calculation_values[isInterestRateFixed ? 'interest_rate_fixed' : 'interest_rate'] }}</span>
      </div>

      <div class="col-12 col-sm-4 detail-calculation__number" v-show="isResidualValueShown">
        <span class="h3">{{ $filters.formatNumber(financingValues.residualValue) }} €</span>
        <span>{{ contentData.calculation_values.residual_value }} <InfoIcon :slug="infoIconTexts.residualValue" /></span>
      </div>
      <div class="col-12 col-sm-4 detail-calculation__number">
        <span class="h3">{{ $filters.formatNumber(financingValues.totalCost) }} €</span>
        <span>
          {{ contentData.calculation_values.cost_total }}
           <InfoIcon :slug="infoIconTexts.totalCost" :value="financingValues.processingCost" />
         </span>
      </div>
      <div class="col-12 col-sm-4 detail-calculation__number">
        <span class="h3">{{ $filters.formatNumber(financingValues.eom) }}%</span>
        <span>{{ contentData.calculation_values.eom }} <InfoIcon :slug="infoIconTexts.eom" :value="financingValues.eom" /></span>
      </div>
    </div>

    <div class="detail-calculation__credit-box" v-show="creditPrice">
        <h2><span class="bg-green-1">{{ contentData.credit_price_title }}</span></h2>

        <div class="detail-financing-types__description">
          <span class="detail-financing-types__description-title h3">{{ contentData.credit_box.title }}</span>
          <p class="detail-financing-types__description-text">{{ contentData.credit_box.description }}</p>
        </div>

        <div class="detail-calculation__numbers grid">
            <div class="col-12 col-sm-4 detail-calculation__number highlight">
              <span class="h3"><span class="bg-green-1">- {{ $filters.formatNumber(creditPrice) }} €</span></span>
              <span>
                {{ contentData.credit_box.value_subtitle }}
                 <InfoIcon :slug="'credit_price_info'" />
               </span>
            </div>
        </div>
    </div>

    <div class="detail-calculation__footer">
      <span>
        {{ contentData.calculation_disclaimer }}
      </span>
      <div class="detail-calculation__footer-cta is-hidden-sm-down">
        <div class="button-group" @click="$emit('offerRequest')">
          <button class="button button--red" id="zelimPonudbo"><span>{{ contentData.offer_cta }}</span></button>
          <div class="button__icon-arrow" id="zelimPonudboP"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-calculation__footer-cta is-hidden-sm-up">
    <div class="button-group" @click="$emit('offerRequest')">
      <button class="button button--red" id="zelimPonudboM"><span>{{ contentData.offer_cta }}</span></button>
      <div class="button__icon-arrow" id="zelimPonudboPM"></div>
    </div>
  </div>

  <div class="container is-hidden">
    <div class="detail-calculation__eurotax">
      <a href="https://eurotax.porsche.si">{{ contentData.eurotax_cta }}</a>
    </div>
  </div>
</div>
  `,
  props: {
    slug: {
      type: String,
      default: '',
    },
    sliderValues: {
      type: Object,
      default: {},
    },
    financingValues: {
      type: Object,
      default: {},
    },
    creditPrice: {
      type: Number,
      default: 0,
    },
  },
  components: { InfoIcon },
  setup (props, { emit }) {
    const contentData = window.DETAIL_PARAMS
    const showKmSlider = Vue.computed(() => !!window.FINANCING_TYPES.filter(type => type.slug === props.slug)[0]?.show_km_slider)
    const showAllSliders = Vue.computed(() => !window.FINANCING_TYPES.filter(type => type.slug === props.slug)[0]?.hide_sliders)

    const isFinancingZero = Vue.computed(() => props.slug === RoutingEnum.FINANCING_TYPE_ZERO)
    const isFinancing44 = Vue.computed(() => props.slug === RoutingEnum.FINANCING_44)
    const isInterestRateFixed = Vue.computed(() => isFinancingZero.value || isFinancing44.value || props.slug === RoutingEnum.FINANCING_718)
    const staticRationsArray = Array.from({ length: 3 }, (_, i) => i + 1)
    const currentYear = parseInt(moment().format('YYYY'))
    const rationText = (rationNr) => contentData.calculation_static_values.ration.replace('$rationNr', rationNr + 1).replace('$year', currentYear + rationNr)

    const periodValue = Vue.ref(props.sliderValues.period)
    const periodSliderOptions = Vue.reactive({
      min: SliderPeriodValues.MIN(props.slug),
      max: SliderPeriodValues.MAX(props.slug),
      step: 12,
      marks: SliderPeriodValues.MARKS(props.slug),
    })
    const depositValue = Vue.ref(props.sliderValues.deposit)
    const depositSliderOptions = Vue.reactive({
      min: SliderDepositValues.MIN(props.slug, periodValue.value),
      max: SliderDepositValues.MAX(),
      marks: SliderDepositValues.MARKS(props.slug, periodValue.value),
    })
    const kmValue = Vue.ref(props.sliderValues.km)
    const kmSliderOptions = {
      min: 10000,
      max: 50000,
      step: 5000,
      marks: {
        10000: '10.000',
        15000: '',
        20000: '',
        25000: '',
        30000: '',
        35000: '',
        40000: '',
        45000: '',
        50000: '50.000',
      },
    }

    const infoIconTexts = Vue.computed(() => {
      const infoIconsPrefix = isFinancingZero.value ? 'zero_' : ''
      return {
        monthlyPayment: infoIconsPrefix + 'calculation_monthly_payment',
        financingValue: 'calculation_financing_total',
        residualValue: 'calculation_residual_value',
        totalCost: 'calculation_cost_total',
        eom: 'calculation_eom',
      }
    })
    const isResidualValueShown = Vue.computed(() => props.slug !== RoutingEnum.FINANCING_TYPE_LEASING &&
      props.slug !== RoutingEnum.FINANCING_44 && props.slug !== RoutingEnum.FINANCING_OFFENSIVE)

    const changedSliders = () => {
      emit('changed', {
        deposit: depositValue.value,
        period: periodValue.value,
        km: kmValue.value,
      })
    }

    const sliderInstructions = Vue.computed(() => window.FINANCING_TYPES.filter(type => type.slug === props.slug)[0]?.slider_instructions || null)
    Vue.watch(() => props.sliderValues, param => {
      depositSliderOptions.min = SliderDepositValues.MIN(props.slug, periodValue.value)
      depositSliderOptions.marks = SliderDepositValues.MARKS(props.slug, periodValue.value)
      if (depositValue.value !== param.deposit) {
        depositValue.value = param.deposit
        changedSliders()
      } else if (depositSliderOptions.min > depositValue.value || depositSliderOptions.max < depositValue.value) {
        depositValue.value = depositSliderOptions.min
        changedSliders()
      }
    })
    Vue.watch(() => props.slug, param => {
      periodSliderOptions.min = SliderPeriodValues.MIN(param)
      periodSliderOptions.max = SliderPeriodValues.MAX(param)
      periodSliderOptions.marks = SliderPeriodValues.MARKS(param)
      depositSliderOptions.min = SliderDepositValues.MIN(props.slug, periodValue.value)
      depositSliderOptions.max = SliderDepositValues.MAX()
      depositSliderOptions.marks = SliderDepositValues.MARKS(param, periodValue.value)
      if (periodValue.value > periodSliderOptions.max) {
        periodValue.value = periodSliderOptions.max
        changedSliders()
      } else if (periodValue.value < periodSliderOptions.min) {
        periodValue.value = periodSliderOptions.min
        changedSliders()
      }
    })

    return {
      contentData,
      showKmSlider,
      showAllSliders,
      isFinancingZero,
      isFinancing44,
      isInterestRateFixed,
      rationText,
      staticRationsArray,
      depositValue,
      depositSliderOptions,
      periodValue,
      periodSliderOptions,
      kmValue,
      kmSliderOptions,
      infoIconTexts,
      isResidualValueShown,
      changedSliders,
      sliderInstructions,
    }
  },
}

export default DetailCalculation
