/* global Vue, VueRouter */

import TransitionCollapse from '../transitions/TransitionCollapse'
import HeaderExternalLink from '../partials/HeaderExternalLink'
import HeaderInternalLink from '../partials/HeaderInternalLinks'
import { RoutingEnum } from '../enums/RoutingEnum'

const Header = {
  components: { TransitionCollapse, HeaderExternalLink, HeaderInternalLink },
  template: `
<div class="header-wrapper" :class="{ sticky: isSticky }">
  <div class="container" :class="{'bg-white-md-down': mobileMenuShow}">
    <div v-show="defaultHeader">
      <div class="header" :class="{open: mobileMenuShow}">
        <img class="header__logo" src="/media/brand/porsche-financiranje-logo.svg" @click="router.push({name: 'home'})" />

        <HeaderInternalLink :desktopOnly="true" />

        <HeaderExternalLink :desktopOnly="true" />

        <div class="header__burger-menu is-hidden-md-up" @click="toggleMenu()">
          <div class="line"></div>
        </div>
      </div>

      <TransitionCollapse>
        <div class="mobile-header is-hidden-md-up" v-show="mobileMenuShow">
          <HeaderInternalLink />

          <HeaderExternalLink />
        </div>
      </TransitionCollapse>
    </div>

    <div v-show="!defaultHeader">
      <div class="header detail-height">
        <div class="header__back" @click="router.back()">
          <span class="header__back-arrow"></span>
          <span class="header__back-text is-hidden-sm-down">Nazaj</span>
        </div>

        <img class="header__logo breakpoint-sm" src="/media/brand/porsche-financiranje-logo.svg" @click="router.push({name: 'home'})" />

        <div class="header__empty-right">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
<div ref="bottomHeadEl"></div>
  `,
  setup () {
    const mobileMenuShow = Vue.ref(false)
    const isSticky = Vue.ref(false)
    const defaultHeader = Vue.ref(true)
    const bottomHeadEl = Vue.ref(null)
    const toggleMenu = () => {
      mobileMenuShow.value = !mobileMenuShow.value
    }
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()

    Vue.watch(() => route.name, name => { defaultHeader.value = name !== RoutingEnum.ROUTE_DETAIL })

    let headerObserver = null
    Vue.onMounted(() => {
      headerObserver = new window.IntersectionObserver((entries) => {
        isSticky.value = !entries[0].isIntersecting
      }, {
        rootMargin: '0px',
        threshold: 1.0,
      })
      headerObserver.observe(bottomHeadEl.value)
    })

    Vue.onUnmounted(() => {
      headerObserver.disconnect()
    })

    return {
      mobileMenuShow,
      isSticky,
      defaultHeader,
      bottomHeadEl,
      router,
      toggleMenu,
    }
  },
}

export default Header
