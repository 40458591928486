/* global Vue, VueRouter */

import DetailVehicle from '../components/DetailVehicle'
import ModalChangePrice from '../components/ModalChangePrice'
import ModalOffer from '../components/ModalOffer'
import FinancingTypeCalculation from '../helpers/FinancingTypeCalculation'
import CalculationDefaultValues from '../helpers/CalculationDefaultValues'
import DetailFinancingTypes from '../partials/DetailFinancingTypes'
import DetailCalculation from '../partials/DetailCalculation'
import ModelHelper from '../helpers/ModelHelper'
import PaymentPeriodHelper from '../helpers/PaymentPeriodHelper'
import { RoutingEnum } from '../enums/RoutingEnum'
import FinancingCalculationZero from '../helpers/FinancingCalculationZero'
import ErrorNotification from '../helpers/ErrorNotification'

const Detail = {
  template: `
<div class="detail header-padding__detail">
  <DetailVehicle
    :vehicle="vehicle"
    :price="priceTotal"
    :slug="financingTypeSlug"
    :monthlyPayment="financingValues.monthlyPayment"
    :periodText="periodText"
    @changePrice="showPriceModal = true"
    @scroll="scrollToCalculation()" />

  <div ref="calculationStartEl"></div>
  <DetailFinancingTypes
    @changed="changeSelectedFinancingType"
    :modelName="vehicle.model_name"
    :fuel="vehicle.fuel"
    :vehicleCode="vehicle.vehicle_code"
    :isUsed="vehicle.state_key === 'used'" />

  <DetailCalculation
    :sliderValues="sliderValues"
    :financingValues="financingValues"
    :slug="financingTypeSlug"
    :creditPrice="creditPrice"
    @offerRequest="activateOfferModal"
    @changed="changeSliderValues" />

</div>

<ModalChangePrice :open="showPriceModal" @close="closeModals()" @priceChanged="priceChange" :key="modalKey" />
<ModalOffer
  :key="modalKey"
  :open="showOfferModal"
  :vehicle="vehicle"
  :price="priceTotal"
  :sliderValues="sliderValues"
  :slug="financingTypeSlug"
  @close="closeModals()" />

<ErrorNotification :slug="financingZeroError" :value="financingZeroMinMonthlyPremium" />
  `,
  components: { DetailVehicle, DetailFinancingTypes, DetailCalculation, ModalChangePrice, ModalOffer, ErrorNotification },
  setup () {
    const contentData = window.DETAIL_PARAMS
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const calculationStartEl = Vue.ref(null)
    const modalKey = Vue.ref(0)
    const showPriceModal = Vue.ref(false)
    const showOfferModal = Vue.ref(false)
    const activateOfferModal = () => { showOfferModal.value = true }
    const closeModals = () => {
      showPriceModal.value = false
      showOfferModal.value = false
      modalKey.value++
    }
    const financingTypeSlug = Vue.ref(route.params.financing_type)
    const sliderValues = Vue.ref({
      deposit: CalculationDefaultValues.DEPOSIT,
      period: CalculationDefaultValues.PERIOD(financingTypeSlug.value),
      km: CalculationDefaultValues.MEGAMETER * 1000,
    })
    const fixedSliderValues = () => {
      sliderValues.value.period = CalculationDefaultValues.PERIOD(financingTypeSlug.value)
      sliderValues.value.deposit = CalculationDefaultValues.DEPOSIT(financingTypeSlug.value)
    }
    fixedSliderValues()

    const allVehicles = route.meta.api.vehicles
    const vehicleValue = (vehicleId) => allVehicles.filter(car => car.vehicle_code === vehicleId)[0] || {}
    const vehicle = Vue.ref(vehicleValue(route.params.vehicle))
    const validateVehicle = () => {
      if (route.params.vehicle && !vehicle.value.price) {
        router.push({ name: '404' })
      }
    }
    validateVehicle()
    const priceTotal = Vue.ref(vehicle.value.price)
    const creditPrice = ModelHelper.taycanCreditPrice(vehicle.value.model_name, vehicle.value.price)

    const financingZeroMinMonthlyPremium = FinancingCalculationZero.minMonthlyPremium
    const financingZeroError = Vue.ref(null)
    const calculateFinancingValues = () => {
      const calculation = FinancingTypeCalculation(
        financingTypeSlug.value,
        sliderValues.value.deposit,
        sliderValues.value.period,
        priceTotal.value,
        true,
        sliderValues.value.km / 1000,
      )
      if (financingTypeSlug.value === RoutingEnum.FINANCING_TYPE_ZERO && calculation.monthlyPayment < financingZeroMinMonthlyPremium) {
        sliderValues.value.deposit = CalculationDefaultValues.DEPOSIT
        sliderValues.value = { ...sliderValues.value }
        financingZeroError.value = 'monthly_payment_too_small'
        setTimeout(() => { financingZeroError.value = null }, 20)
        return calculateFinancingValues()
      }
      return calculation
    }
    const financingValues = Vue.ref(calculateFinancingValues())
    const periodText = Vue.computed(() => PaymentPeriodHelper.text(financingTypeSlug.value))

    const changeSelectedFinancingType = (type) => {
      financingTypeSlug.value = type.slug
      fixedSliderValues()
      financingValues.value = calculateFinancingValues()
    }
    const changeSliderValues = (newValues) => {
      sliderValues.value = { ...newValues }
      financingValues.value = calculateFinancingValues()
    }

    const priceChange = (newPrice) => {
      closeModals()
      priceTotal.value = newPrice
      creditPrice.value = ModelHelper.taycanCreditPrice(vehicle.value.model_name, newPrice)
      financingValues.value = calculateFinancingValues()
    }

    const scrollToCalculation = () => {
      calculationStartEl.value.scrollIntoView() // no animation because of sticky header problem
    }

    Vue.watch(() => route.params.vehicle, param => {
      vehicle.value = vehicleValue(param)
      validateVehicle()
      priceTotal.value = vehicle.value.price
      financingValues.value = calculateFinancingValues()
    })
    Vue.watch(() => route.params.financing_type, param => {
      if (param) changeSelectedFinancingType(param)
    })

    return {
      contentData,
      calculationStartEl,
      modalKey,
      showPriceModal,
      showOfferModal,
      activateOfferModal,
      closeModals,
      financingTypeSlug,
      sliderValues,
      vehicle,
      priceTotal,
      creditPrice,
      financingZeroMinMonthlyPremium,
      financingZeroError,
      financingValues,
      periodText,
      priceChange,
      changeSelectedFinancingType,
      changeSliderValues,
      scrollToCalculation,
    }
  },
  methods: {
    showInfo () {
      const text = (window.INFO_ICONS[this.slug] || '')
        .replace('%s', this.$filters.formatNumber(this.value))
      this.$toast.error(text, { position: 'top-right', duration: 7000 })
    },
  },
}

export default Detail
