/* global Vue */

const Loader = {
  template: `
<div class="loader" v-show="isLoaderOpened">
    <img class="loader__image" src="/media/icons/loader.gif" alt="loading image" />
</div>
  `,
  setup () {
    const isLoaderOpened = Vue.inject('isLoaderOpened')
    return { isLoaderOpened }
  },
}

export default Loader
