/* global Vue, Swiper, SwiperSlide */

import { Navigation, Pagination } from 'swiper'

const DetailVehicleSlider = {
  template: `
<div class="detail-vehicle__slider">
  <div class="detail-vehicle__slider-wrapper">
    <div class="detail-vehicle__slider-images">
      <div class="detail-vehicle__slider-icon left" ref="sliderIconLeftEl"></div>
      <div class="detail-vehicle__slider-icon right" ref="sliderIconRightEl"></div>

      <Swiper
        class="swiper"
        v-if="initSwiper"
        :loop="true"
        :modules="swiperModules"
        :navigation="sliderNavigation"
        :pagination="sliderPagination">
        <SwiperSlide v-for="image in images">
          <div
            class="swiper-slide"
            :style="{ backgroundImage: 'url(' + image + ')' }"></div>
        </SwiperSlide>
      </Swiper>
      <div class="swiper-pagination" ref="swiperPaginationEl"></div>
    </div>
  </div>
</div>
  `,
  components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
      default: [],
    },
  },
  setup () {
    const swiperPaginationEl = Vue.ref(null)
    const sliderIconRightEl = Vue.ref(null)
    const sliderIconLeftEl = Vue.ref(null)
    const sliderNavigation = Vue.ref(null)
    const sliderPagination = Vue.ref({
      el: null,
      clickable: true,
      bulletActiveClass: 'active',
    })
    const initSwiper = Vue.ref(false)

    Vue.onMounted(() => {
      sliderNavigation.value = {
        nextEl: sliderIconRightEl.value,
        prevEl: sliderIconLeftEl.value,
      }
      sliderPagination.value.el = swiperPaginationEl.value
      // swiper doesn't have reactive settings, is hidden behind v-if until element refs are available
      initSwiper.value = true
    })

    return {
      swiperPaginationEl,
      sliderIconRightEl,
      sliderIconLeftEl,
      sliderNavigation,
      sliderPagination,
      initSwiper,
      swiperModules: [Navigation, Pagination],
    }
  },
}

export default DetailVehicleSlider
