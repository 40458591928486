export const RouteApiResolver = {
  data: null,
  getData (to, from, next) {
    if (RouteApiResolver.data) {
      to.meta.api = RouteApiResolver.data
      next()
      return
    }

    RouteApiResolver.fetchFromApi().then(data => {
      // TEMPORARY: filter out Macan configurator vehicles, except Electric
      data.vehicles = data.vehicles.filter(vehicle => vehicle.stock_url || vehicle.model_name !== 'Macan' || vehicle.model_name.includes('Electric'))
      RouteApiResolver.data = data
      to.meta.api = data
      RouteApiResolver.resetTimeout()
      next()
    }, err => {
      RouteApiResolver.data = null
      console.error(err)
      next(false)
    })
  },
  fetchFromApi () {
    return new Promise((resolve, reject) => {
      fetch(`${window.API_URL}/api/financing-api/porsche-data`, { method: 'GET' })
        .then(response => response.json(), err => reject(err))
        .then(response => resolve(response.records), err => reject(err))
    })
  },
  resetTimeout () {
    setTimeout(() => {
      RouteApiResolver.data = null
    }, 3600000) // one hour
  },
}
