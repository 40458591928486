const ErrorNotification = {
  functional: true,
  props: {
    slug: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    slug () { this.showInfo() },
  },
  methods: {
    showInfo () {
      if (!this.slug) {
        return
      }

      const text = (window.ERROR_NOTIFICATIONS[this.slug] || '')
        .replace('%s', this.value)
      this.$toast.error(text, { position: 'top-right', duration: 7000 })
    },
  },
}

export default ErrorNotification
