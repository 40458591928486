/* global Vue */

import Modal from './Modal'

const ModalChangePrice = {
  template: `
<Modal :open="open" @close="$emit('close')">
  <h2 class="modal-mask__title">{{ content.title }}</h2>
  <p class="modal-mask__text">{{ content.text }}</p>

  <div class="button-group no-hover">
    <input
      class="button"
      :placeholder="content.placeholder"
      v-model="price"
      @keyup="validateInput" />
    <div class="button__icon-arrow" @click="change()"></div>
  </div>

  <span class="modal-mask__error" v-show="showError">{{ content.error }}</span>
</Modal>
  `,
  components: { Modal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const content = window.MODALS.price_change
    const price = Vue.ref('')
    const showError = Vue.ref(false)

    const validateInput = (e) => {
      let parsedValue = e.target.value.replace(/[^0-9,]/g, '')
      if ((parsedValue.match(/,/g) || []).length > 1) {
        const splitedValues = parsedValue.split(',')
        parsedValue = `${splitedValues[0]},${splitedValues[1]}`
        splitedValues.splice(0, 2)
        parsedValue += splitedValues.join('')
      }

      if (parsedValue !== e.target.value) {
        e.target.value = parsedValue
      }
      if (e.key === 'Enter') { change() }
    }
    const change = () => {
      showError.value = false
      const newVal = parseFloat(price.value.replace(',', '.'))
      if (newVal >= 10000 && newVal <= 1000000) {
        emit('priceChanged', newVal)
      } else {
        showError.value = true
      }
    }

    return {
      content,
      price,
      showError,
      validateInput,
      change,
    }
  },
}

export default ModalChangePrice
