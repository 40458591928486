const ModelHelper = {
  isTaycan: (modelName) => {
    return modelName.toLowerCase().indexOf('taycan') === 0
  },
  taycanCreditPrice: (modelName, price) => {
    return ModelHelper.isTaycan(modelName) ? Math.round(0.015 * price * 100) / 100 : 0
  },
}

export default ModelHelper
