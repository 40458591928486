/* global Vue, VueRouter */

import { RoutingEnum } from '../enums/RoutingEnum'
import FinancingTypeCalculation from '../helpers/FinancingTypeCalculation'
import CalculationDefaultValues from '../helpers/CalculationDefaultValues'
import InfoIcon from '../partials/InfoIcon'
import ModelHelper from '../helpers/ModelHelper'
import PaymentPeriodHelper from '../helpers/PaymentPeriodHelper'

const Vehicle = {
  template: `
<div class="vehicle">
  <div class="vehicle__hero">
    <div class="vehicle__hero-label" v-show="vehicle.stock_url">{{ vehicle.state_name }}</div>
    <div class="vehicle__hero-image" @click="ctaClick()">
      <div class="vehicle__hero-image-img" :style="{ backgroundImage: 'url(' + (vehicle.thumbnail || '') + ')' }"></div>
    </div>
  </div>
  <div class="vehicle__name">
    <span class="h3">{{ vehicle.name }}</span>
  </div>
  <div class="vehicle__features">
    <div class="vehicle__feature">
      <span class="vehicle__feature-value">{{ vehicle.kw }} kW/{{ vehicle.hp }} KM</span>
      <span class="vehicle__feature-name">{{ contentData.technical_power }}</span>
    </div>
    <div class="vehicle__feature" v-show="isStockVehicle">
      <span class="vehicle__feature-value">{{ vehicle.fuel }}</span>
      <span class="vehicle__feature-name">{{ contentData.technical_fuel }}</span>
    </div>
    <div class="vehicle__feature" v-show="isStockVehicle">
      <span class="vehicle__feature-value">{{ vehicle.transmission_description }}</span>
      <span class="vehicle__feature-name">{{ contentData.technical_transmission }}</span>
    </div>
    <div class="vehicle__feature" v-show="!isStockVehicle">
      <span class="vehicle__feature-value">{{ vehicle.acceleration }}</span>
      <span class="vehicle__feature-name">{{ contentData.technical_acceleration }}</span>
    </div>
    <div class="vehicle__feature" v-show="!isStockVehicle">
      <span class="vehicle__feature-value">{{ vehicle.max_speed }}</span>
      <span class="vehicle__feature-name">{{ contentData.technical_max_speed }}</span>
    </div>
    <div class="vehicle__feature  price">
      <span class="vehicle__feature-value  h3">{{ $filters.formatNumber(vehicle.price) }} €</span>
      <span class="vehicle__feature-name">{{ $filters.formatNumber(monthlyPayment) }} €/{{ periodText }}</span>
    </div>
    <div class="vehicle__feature  credit-price" v-if="creditPrice">
      <span class="vehicle__feature-value">
        {{ contentData.credit_price_title }} <br /><span class="text-small">{{ contentData.credit_price_subtitle }}</span>
      </span>
      <span class="vehicle__feature-name">
         - {{ $filters.formatNumber(creditPrice) }} €/{{ periodText }} <InfoIcon :slug="'credit_price_info'" />
      </span>
    </div>
  </div>
  <div class="vehicle__cta">
    <div class="button-group" @click="ctaClick()">
      <button class="button"><span>{{ detailCtaText }}</span></button>
      <div class="button__icon-arrow"></div>
    </div>
  </div>
</div>
  `,
  props: {
    vehicle: {
      type: Object,
      default: null,
    },
  },
  components: { InfoIcon },
  setup (props) {
    const contentData = window.DETAIL_PARAMS
    const isStockVehicle = !!props.vehicle.stock_url
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const monthlyPayment = Vue.ref(FinancingTypeCalculation(
      route.params.financing_type,
      CalculationDefaultValues.DEPOSIT(route.params.financing_type),
      CalculationDefaultValues.PERIOD(route.params.financing_type),
      props.vehicle.price,
      false,
      CalculationDefaultValues.MEGAMETER,
    ).monthlyPayment)
    const creditPrice = ModelHelper.taycanCreditPrice(props.vehicle.model_name, props.vehicle.price)
    const periodText = Vue.computed(() => PaymentPeriodHelper.text(route.params.financing_type))
    const detailCtaText = Vue.computed(() =>
      window.FINANCING_TYPES.filter(financingType => financingType.slug === route.params.financing_type)[0]?.cta_special_text ||
        contentData.cta_text,
    )

    const ctaClick = () => {
      router.push({
        name: RoutingEnum.ROUTE_DETAIL,
        params: {
          financing_type: route.params.financing_type,
          model_name: encodeURIComponent(props.vehicle.name.replaceAll(' ', '-')),
          vehicle: props.vehicle.vehicle_code,
        },
      })
    }

    return {
      contentData,
      isStockVehicle,
      monthlyPayment,
      creditPrice,
      periodText,
      detailCtaText,
      ctaClick,
    }
  },
}

export default Vehicle
