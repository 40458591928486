/* global Vue, VueRouter */

import { RoutingEnum } from '../enums/RoutingEnum'

const ListingTitle = {
  template: `
<div class="listing__title">
  <h2>{{ title }}</h2>
  <p v-show="!stock">{{ contentData.available_vehicles_subtitle }}</p>
  <p>{{ contentData.available_vehicles_number }}: {{ count }}</p>

  <div class="listing__order" @click="orderClick()" v-show="!stock">
    <span class="listing__order-icon" :class="{up: arrowUp}"></span>
    <span>{{ contentData.order_by_price }}</span>
  </div>
</div>
  `,
  props: {
    chosenModel: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    stock: {
      type: Boolean,
      default: false,
    },
    arrowUp: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const contentData = window.LISTING_PARAMS
    const route = VueRouter.useRoute()
    const routingModel = Vue.ref(route.params.model)
    Vue.watch(() => route.params.model, param => { routingModel.value = param })

    const calculateModelName = () => {
      if (routingModel.value !== RoutingEnum.ALL_MODELS && routingModel.value !== RoutingEnum.MODELS_HYBRID) {
        return routingModel.value
      } else {
        return props.chosenModel
      }
    }
    const modelName = Vue.ref(calculateModelName())
    const makeTitle = () => {
      return props.stock
        ? `${contentData.stock_vehicles_title} ${modelName.value} ${contentData.stock_vehicles_title_suffix}`
        : `${contentData.available_vehicles_title} ${modelName.value}`
    }
    const title = Vue.ref(makeTitle())

    const orderClick = () => { emit('orderClick') }

    Vue.watch(() => props.chosenModel, param => {
      modelName.value = calculateModelName()
      title.value = makeTitle()
    })

    return {
      title,
      contentData,
      modelName,
      orderClick,
    }
  },
}

export default ListingTitle
